@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,100;9..144,200;9..144,300;9..144,400;9..144,500;9..144,600;9..144,700;9..144,800;9..144,900&display=swap');


@font-face {
    font-family: SharpSansRegular;
    src: url(./fonts/SharpSans.otf);
}

@font-face {
    font-family: SharpSansThin;
    src: url(./fonts/SharpSansThin.otf);
}

@font-face {
    font-family: SharpSansLight;
    src: url(./fonts/SharpSansLight.otf);
}

@font-face {
    font-family: SharpSansMedium;
    src: url(./fonts/SharpSansMedium.otf);
}

@font-face {
    font-family: SharpSansSemibold;
    src: url(./fonts/SharpSansSemibold.otf);
}

@font-face {
    font-family: SharpSansBold;
    src: url(./fonts/SharpSansBold.otf);
}

@font-face {
    font-family: SharpSansExtrabold;
    src: url(./fonts/SharpSansExtrabold.otf);
}

$pri-color: #192451;
$sec-color: #CADFED;

$pri-text-color: #222222;
$sec-text-color: #fff;
$bg-pri-color: #192451;
$bg-sec-color: #FAFAFA;

$sec-16px-size: 1rem;
$sec-18px-size: 1.125rem;
$sec-20px-size: 1.25rem;
$sec-24px-size: 1.5rem;
$sec-54px-text: 3.375rem;
$sec-50px-text: 3.125rem;
$sec-65px-text: 4.063rem;

$font-thin: 100;
$font-light: 300;
$font-regular: 400;
$font-semibold: 600;
$font-bold: 700;


@mixin fraunces-font()
{
    font-family: 'Fraunces', serif;
}

@mixin SharpSansRegular-font()
{
    font-family: 'SharpSansRegular';
}

@mixin primary-book-btn()
{
    font-family: 'SharpSansSemibold';
    border-radius: 6px;
    background-color: $bg-sec-color;
    border: 1px solid $bg-sec-color;
    transition: ease-in 0.2s;

    &:hover
    {
        background-color: transparent;
        border: 1px solid $bg-sec-color;    
    }
}

@mixin section-padding()
{
    padding: 80px 0;
}

@mixin letter-spacing()
{
    letter-spacing: 0.72px;
}

@mixin sec-main-title()
{
    color: $pri-color;
    font-size: $sec-18px-size;
    font-family: 'SharpSansBold';
    background-color: $sec-color;
    border-radius: 30px;
    padding: 15px 30px;
    text-transform: capitalize;
}

@mixin sec-main-title2()
{
    color: $pri-color;
    font-size: $sec-54px-text;
    @include fraunces-font;
    font-weight: $font-bold;
    line-height: 60px;
    text-transform: capitalize;
}

@mixin sec-left-side()
{
    display: flex;
    flex-direction: column;
    gap: 26px;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
}

@mixin sec-right-side()
{
    display: flex;
    flex-direction: column;
    gap: 26px;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
}

@mixin read-button()
{
    @include primary-book-btn;
    padding: 15px 40px;
    color: #fff;
    text-decoration: none;
    background-color: $bg-pri-color;
    border: 1px solid $bg-pri-color;
    width: 29%;

    svg 
    {
        margin-left: 7px;
        transition: ease-in 0.2s;

        path 
        {
            fill: #fff;
        }
    }

    &:hover
    {
        color: $pri-color;
        background-color: transparent;
        border: 1px solid $pri-color;    

        svg 
        {
            transform: translate(4px, 0px);
            path
            {
                fill: $pri-color;
            }
        }
    }

}

* 
{
    scroll-behavior: smooth;
}

html 
{
    overflow-x: hidden;
}

body
{
    font-family: 'SharpSansRegular' !important;
    margin: 0px;
    padding: 0px;
    color: $pri-text-color;
    box-sizing: border-box;
    line-height: 25px !important;
    font-size: $sec-16px-size;
    background-color: $bg-sec-color;
}

::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #fafafa; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: $pri-color; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: $pri-color; 
  }

.scroll-up-btn
{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $sec-color;
    position: fixed;
    height: 55px;
    width: 55px;
    background: $sec-color;
    right: 1.875rem;
    bottom: 1.875rem;
    text-align: center;
    line-height: 2.813rem;
    color: #fff;
    z-index: 9999;
    font-size: 1.875rem;
    border-radius: 50%;
    border-bottom-width: 2px;
    cursor: pointer;
    transition: 0.4s;

    &:hover
    {
        background: $pri-color;
        border: 1px solid $pri-color;

        svg 
        {
            path 
            {
                fill: $sec-color;
            }
        }
    }
}

.navbar
{
    font-family: 'SharpSansSemibold';
    background-color: $pri-color;

    .logo
    {
        height: 3.75rem;
    width: 7.688rem;
    object-fit: fill;
    }

    .nav-item 
    {
        margin: 0px 5px;
    }
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #192451 !important;
    background: #CADFED;
    border-radius: 6px;
}

.navbar-expand-lg .navbar-nav .nav-link
{
    color: $sec-text-color;
    font-size: $sec-16px-size;
    font-weight: $font-bold;
    padding-right: 0.7rem !important;
    padding-left: 0.7rem !important;
}

.navbar 
{
    .book-consultant-btn
    {
        @include primary-book-btn;
        color: $pri-color !important;

        &:hover
        {
            color: $bg-sec-color !important; 
        }
    }
}

.navbar-nav
{
    .dropdown-item {
        width: 100%;
        padding: 0.55rem 1.5rem;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        color: $pri-color;
        background-color: $sec-color;
    }
}

// .navbar-expand-lg .navbar-nav .nav-link {
//     padding: 1rem 0.7rem !important;
// }

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: $sec-color;
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 1px #fff;
}

.navbar-toggler
{
    border: unset;
}

.main-footer
{   
    padding: 4.25rem 0rem;
    border-bottom: 1px solid rgba(67, 67, 67, 0.37);

    h5
    {
        font-size: $sec-16px-size;
        font-family: 'SharpSansMedium';
        text-align: left;
        line-height: 1.5 !important;
        color: $pri-text-color;
        padding-bottom: 10px;
        margin: 0rem 0rem;
    }

    img 
    {
        width: 80%;
        object-fit: cover;
        padding-bottom: 10px;
        float: left;
    }

    h4 
    {
        font-family: 'SharpSansBold';
        font-size: $sec-20px-size;
        color: $pri-color;
        text-align: left;
        margin-bottom: 1.5rem;
    }

    .links-titles
    {
        padding-inline-start: 0px;
        list-style-type: none;
        margin-block-start: 0rem;
        margin-block-end: 0rem;
        text-align: left;

        li 
        {
            margin-bottom: 0.8rem;
            a 
            {
                text-decoration: none;
                color:$pri-text-color;
                font-family: 'SharpSansMedium';
                transition: ease-in 0.4s;

                &:hover 
                {
                    text-decoration: underline;
                    text-underline-position: under;
                }
               
            }

        }
    }
    .contactus-details
    {
        font-family: 'SharpSansMedium';
        display: flex;
        justify-content: flex-start;
        gap: 0.5rem;
        align-items: flex-start;

        &:nth-child(5)
        {
            margin-bottom: 01.8rem;
        }

        p
        {
            margin-bottom: 0.8rem;
        }

        a
        {
            color: $pri-text-color;
            font-family: 'SharpSansMedium';
            text-underline-position: under;
        }
    }

    .social-icons
    {
        .d-flex
        {
            gap: 0.5rem;
        }

        .icon-bg
        {
            background-color: #CADFED;
            border-radius: 50%;
            height: 45px;
            width: 45px;
            display: flex;
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;
            cursor: pointer;
            transition: 0.4s;

            &:hover
            {
                background: $pri-color;
                border: 1px solid $pri-color;

                svg 
                {
                    path 
                    {
                        fill: $sec-color;
                    }
                }
            }
        }
    }
}

.mini-footer
{
    padding: 25px 0px;

    .copy-right, .dev-name
    {
        font-family: 'Fraunces', serif;
        color: $pri-color;
        font-size: $sec-16px-size;
        font-weight: $font-semibold;
        margin-bottom: 0rem;
    }

    .copy-right
    {
        text-align: left;
    }

    .dev-name
    {
        text-align: right;

        a 
        {
            color: $pri-color;
            text-underline-position: under;
        }
    }

}

.page-bottom
{
    background-color: $bg-pri-color;
    padding: 108px 0px;
    position: relative;
    
    .sec-content
    {
        text-align: center;

        h2
        {
            @include fraunces-font;
            color: #fff;
            font-size: $sec-54px-text;
            font-weight: $font-bold; 
            margin-bottom: 1rem;
        }

        p
        {
            color: #fff;
            width: 60%;
            margin: 0px auto;
            padding: 0 0 1rem 0;
        }

        h3 
        {
            a 
            {
                @include fraunces-font;
                font-size: 34px;
                color: #fff;
                font-weight: $font-bold;
                text-underline-position: under;
            }
        }

        &::before
        {
            content: '';
            background: url('./images/plan-fly-2.png') no-repeat;
            position: absolute;
            top: 0;
            left: 75%;
            width: 286px;
            height: 216px;
        }

        &::after
        {
            content: '';
            background: url('./images/plan-fly-1.png') no-repeat;
            position: absolute;
            bottom: 0;
            right: 79%;
            width: 263px;
            height: 161px;
        }
    }
}

.main-banner
{
    background-color: $bg-pri-color;
    position: relative;

    .right-sec
    {
        padding: 150px 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &::before
        {
            content: '';
            background: url('./images/plan-fly-3.png') no-repeat;
            position: absolute;
            top: 0;
            width: 300px;
            height: 210px;
            flex-shrink: 0;
        }

        h1 
        
        {
            color: #fff;
            @include fraunces-font;
            font-size: $sec-65px-text;
            font-weight: $font-semibold;
            margin-bottom: 2rem;

            span 
            {
                font-weight: $font-light;
            }
        }

        .key-point1
        {
            display: flex;
            color: #fff;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0.3rem;

            div 
            {
                width: 30px;
                height: 30px;
            }

            p 
            {
                margin-bottom: 0rem;
            }
        }

        .btn-padding 
        {
            padding-top: 60px;
        }

        .book-button
        {
            @include primary-book-btn;
            padding: 15px 40px;
            color: $pri-color;
            text-decoration: none;

            svg 
            {
                margin-left: 7px;
                transition: ease-in 0.2s;
            }

            &:hover
            {
                color: #fff;

                svg 
                {
                    transform: translate(4px, 0px);
                    path
                    {
                        fill: #fff;
                    }
                }
            }
        }
    }

    .banner-flex
    {
        display: flex;
        justify-content: space-between;

        .sec-width
        {
            width: 50%;
        }
    }

    .right-sec 
    {
        margin-left: 100px;
    }

    .left-sec
    {

        img 
        {
            width: 100%;
        }
    }
    
}

.about-us
{
    @include section-padding;

    .right-side
    {
        img 
        {
            object-fit: cover;
            width: 100%;
        }
    }

    .left-side
    {
        @include sec-left-side;
        margin-left: 50px;

        .sec-title
        {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;

            span
            {
                @include sec-main-title;
                @include letter-spacing;
            }
        }

        a 
        {
            @include read-button;
        }
        
        .sec-title2
        {
           h2 
           {
            @include sec-main-title2;
            margin-bottom: 0;
           }
        }

        .sec-text
        {
            p
            {
               color: $pri-text-color;
               font-family: SharpSansMedium;
            }
        }
    }

}

.our-success
{
    @include section-padding;

    .right-side
    {
        @include sec-left-side;
        position: relative;

        .sec-title
        {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;

            span
            {
                @include sec-main-title;
                @include letter-spacing;
            }
        }
        
        .sec-title2
        {
           h2 
           {
            @include sec-main-title2;
            margin-bottom: 0;
           }
        }

        .sec-text
        {
            p
            {
               color: $pri-text-color;
               font-family: 'SharpSansMedium';
               width: 70%;
            }
        }
    }

    .counters-sec 
    {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        z-index: 1;

        &::after
        {
            content: '';
            width: 408.327px;
            height: 316.05px;
            transform: rotate(1.98deg);
            background: url('./images/plan-fly-4.png') no-repeat;
            position: absolute;
            right: 28px;
            bottom: -28px;
            z-index: -1;
        }
       

        .counters-div 
        {
            padding: 35px 55px;
            width: 35%;
            background-color: #fff;
            display: flex;
            gap: 3.5rem;
            align-content: center;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            &:nth-child(1)
            {
                border-radius: 20px 0px 0px 0px;
                border-right: 1px solid #E8E8E8;
                border-bottom: 1px solid #E8E8E8;
            }
            &:nth-child(2)
            {
                border-radius: 0px 20px 0px 0px;
                border-bottom: 1px solid #E8E8E8;
                border-left: 1px solid #E8E8E8;
            }
            &:nth-child(3)
            {
                border-radius: 0px 0px 0px 20px;
                border-top: 1px solid #E8E8E8;
                border-right: 1px solid #E8E8E8;
            }
            &:nth-child(4)
            {
                border-radius: 0px 0px 20px 0px;
                border-top: 1px solid #E8E8E8;
                border-left: 1px solid #E8E8E8;
            }

            &::before
            {
                content: '';
                width: 3px;
                height: 76px;
                background:$pri-color;
                position: absolute;
                left: 0;
            }
        }

        .counters-icon 
        {
            width: 85px;
            height: 85px;
            flex-shrink: 0;
            background: #CADFED;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 3px 6px rgba(164, 164, 164, 0.25);
        }

        .conunters-nos
        {
            

            h2 
            {
                color: $pri-color;
                @include fraunces-font;
                line-height: 70px;
                font-size: $sec-50px-text;
                font-weight: $font-bold;
                margin-bottom: 0px;
            } 

            p 
            {
                margin-bottom: 0px;
                font-size: $sec-18px-size;
                color: $pri-text-color;
                font-family: 'SharpSansMedium';
            }
        }
    }
}

.our-services
{
    @include section-padding;

    .right-side
    {
        @include sec-left-side;
        align-items: center;
        align-content: center;
        position: relative;

        .sec-title
        {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;

            span
            {
                @include sec-main-title;
                @include letter-spacing;
            }
        }
        
        .sec-title2
        {
           h2 
           {
            @include sec-main-title2;
            margin-bottom: 0;
            text-transform: capitalize;
           }
        }

        .sec-text
        {
            p
            {
               color: $pri-text-color;
               font-family: 'SharpSansMedium';
               text-align: center;
               width: 60%;
               margin: 0 auto;
            }
        }
    }

    .our-services-slider
    {
        padding-top: 50px;

        .sliderCard
        {
            position: relative;
            box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
            border-radius: 20px;
            margin-bottom: 0.8rem;

            img {   
                width: 100%;
                border-radius: 22px;
                object-fit: cover;


            }

            .slideNumber
            {
                position: absolute;
                top: 4%;
                left: 20px;
                width: 69px;
                height: 69px;
                background-color: $sec-color;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                @include fraunces-font;
                font-weight: $font-bold;
                color: $pri-color;
                font-size: $sec-20px-size;
            }
            .card-details
            {
                position: absolute;
                top: 70%;

                .visa-title
                {
                    background-color: $sec-color;
                    color: $pri-color;
                    font-size: $sec-20px-size;
                    border-radius: 0px 22px 22px 0px;
                    font-family: 'SharpSansBold';
                    font-size: $sec-18px-size;
                    padding: 15px 30px;
                    margin-bottom: 16px;
                    text-align: center;

                    p
                    {
                        margin-bottom: 0;
                        line-height: normal;
                        text-transform: capitalize;
                    }

                }
                .readmore
                {
                   
                    a 
                    {
                        @include read-button;
                        background-color: $sec-color;
                       width: unset;
                        color: $pri-color;
                        font-size: $sec-20px-size;
                        text-decoration: none;
                        border-radius: 0px 22px 22px 0px;
                        font-family: 'SharpSansBold';
                        font-size: $sec-18px-size;
                        padding: 13px 39px;
                        margin-bottom: 16px;
                        border: 1px solid $sec-color;
                        transition: ease-in 0.2s;

                        &:hover
                        {
                            border-left: 0px;
                            background-color: $pri-color;
                            color: $bg-sec-color;

                            svg 
                        {
                            path 
                            {
                                fill: $bg-sec-color;
                            }
                        }
                        }

                        svg 
                        {
                            path 
                            {
                                fill: $pri-color;
                            }

                        }
                    }
                }
            }
        }

        .slick-slide >div 
        {
            margin: 0 10px;
        }

        .slick-prev, .slick-next
        {
            width: 69px;
            height: 69px;
           
        }

        .slick-prev:before, .slick-next:before 
        {
            opacity: 1;
            color: $sec-color;
            font-size: 63px;
        }

        .slick-prev {
            left: -70px;
        }

        .slick-next {
            right: -70px;
        }

        .nextarrow 
        {
            background-image: url('./images/arrow-sm.svg');
            background-repeat: no-repeat;
            height: 69px;
            width: 69px;
            background-color: $sec-color;
            border: 1px solid $sec-color;
            border-radius: 50%;
            display: flex;
            background-position: center;
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            padding: 0px;
            right: -70px;
            cursor: pointer;
            transition: 0.4s ease-out;

            &:hover
            {
                background-image: url('./images/arrow-sm-white.svg') !important;
                background-repeat: no-repeat !important;
                display: flex !important;
                background-position: center !important;
                background: $pri-color;
                border: 1px solid $pri-color;

                svg 
                {
                    path 
                    {
                        fill: $sec-color;
                    }
                }
            }
        }

        .prevarrow
        {
            background-image: url('./images/arrow-sm-left.svg');
            background-repeat: no-repeat;
            height: 69px;
            width: 69px;
            background-color: $sec-color;
            border: 1px solid $sec-color;
            border-radius: 50%;
            display: flex;
            background-position: center;
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            padding: 0px;
            left: -70px;
            cursor: pointer;
            transition: 0.4s ease-in-out;

            &:hover
            {
                background-image: url('./images/arrow-sm-left-white.svg') !important;
                background-repeat: no-repeat !important;
                display: flex !important;
                background-position: center !important;
                background: $pri-color;
                border: 1px solid $pri-color;

                svg 
                {
                    path 
                    {
                        fill: $sec-color;
                    }
                }
            }
        }
    }
}

.why-choose-us
{
    @include section-padding;

    .right-side
    {
        img 
        {
            object-fit: cover;
            width: 100%;
        }
    }

    .left-side
    {
        @include sec-left-side;
        margin-left: 50px;

        .sec-title
        {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;

            span
            {
                @include sec-main-title;
                @include letter-spacing;
            }
        }

        a 
        {
            @include read-button;
        }
        
        .sec-title2
        {
           h2 
           {
            @include sec-main-title2;
            margin-bottom: 0;
            text-transform: capitalize;
           }
        }

        .sec-text
        {
            p
            {
               color: $pri-text-color;
               font-family: SharpSansMedium;
            }
        }

        .key-points
        {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            .key-point1
            {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 0.5rem;
                width: 34%;

                svg 
                {
                    background: #cadfed;
                    border-radius: 50%;
                }

                p 
                {
                    margin-bottom: 0rem;
                    font-family: 'SharpSansSemiBold';
                }
            }
        }
        
    }
}


.our-work-process
{
    @include section-padding;

    .right-side
    {
        @include sec-left-side;
        align-items: center;
        align-content: center;
        position: relative;

        .sec-title
        {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;

            span
            {
                @include sec-main-title;
                @include letter-spacing;
            }
        }
        
        .sec-title2
        {
           h2 
           {
            @include sec-main-title2;
            margin-bottom: 0;
           }
        }

        .sec-text
        {
            p
            {
               color: $pri-text-color;
               font-family: 'SharpSansMedium';
               text-align: center;
               margin: 0 auto;
            }
        }
    }

    .process-steps-card
    {
        margin-top: 50px;
        position: relative;

        background: #fff;
        padding: 120px 40px 60px 40px;
        border-radius: 20px;
        box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 10px;

        .steps-nos
        {
            background-color: $sec-color;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $pri-color;
            font-size: $sec-20px-size;
            border-radius: 0px 50px 50px 0px;
            
            font-size: $sec-18px-size;
            padding: 10px 10px 10px 40px;
            margin-bottom: 16px;
            position: absolute;
            left: 0;
            top: 40px;
            box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
            @include fraunces-font;
                font-weight: $font-semibold;


            span
            {
                margin-bottom: 0;
                line-height: normal;
                letter-spacing: 0.72px;
                text-transform: capitalize;
                background-color: #192451;
                color: #fff;
                width: 40px;
                height: 40px;
                text-align: center;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
            }
        }

        .steps-title
        {
            h2 
            {
                @include fraunces-font;
                font-weight: $font-semibold;
                font-size: 24px;
            }
        }

        .steps-content
        {
            p 
            {
                margin-bottom: 0px;
                font-family: 'SharpSansMedium';
            }
        }
    }
}

.our-testi-slider
{
    @include section-padding;

    .right-side
    {
        @include sec-left-side;
        align-items: center;
        align-content: center;
        position: relative;

        .sec-title
        {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;

            span
            {
                @include sec-main-title;
                @include letter-spacing;
            }
        }
        
        .sec-title2
        {
           h2 
           {
            @include sec-main-title2;
            margin-bottom: 0;
            text-align: center;
            width: 80%;
            margin: 0px auto;
           }
        }
    }

    .our-testi-slider1
    {
        margin-top: 20px;

        .nextarrow 
        {
            background-image: url('./images/arrow-sm.svg');
            background-repeat: no-repeat;
            height: 69px;
            width: 69px;
            background-color: $sec-color;
            border: 1px solid $sec-color;
            border-radius: 50%;
            display: flex;
            background-position: center;
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            padding: 0px;
            right: -70px;
            cursor: pointer;
            transition: 0.4s ease-in-out;

            &:hover
            {
                background-image: url('./images/arrow-sm-white.svg') !important;
                background-repeat: no-repeat !important;
                display: flex !important;
                background-position: center !important;
                background: $pri-color;
                border: 1px solid $pri-color;

                svg 
                {
                    path 
                    {
                        fill: $sec-color;
                    }
                }
            }
        }

        .prevarrow
        {
            background-image: url('./images/arrow-sm-left.svg');
            background-repeat: no-repeat;
            height: 69px;
            width: 69px;
            background-color: $sec-color;
            border: 1px solid $sec-color;
            border-radius: 50%;
            display: flex;
            background-position: center;
            font-size: 0;
            line-height: 0;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            padding: 0px;
            left: -70px;
            cursor: pointer;
            transition: 0.4s ease-in-out;

            &:hover
            {
                background-image: url('./images/arrow-sm-left-white.svg') !important;
                background-repeat: no-repeat !important;
                display: flex !important;
                background-position: center !important;
                background: $pri-color;
                border: 1px solid $pri-color;

                svg 
                {
                    path 
                    {
                        fill: $sec-color;
                    }
                }
            }
        }
        

        .slick-slide >div 
        {
            margin: 0 10px;
        }

        .testi-card
        {
            margin-top: 50px;
            position: relative;
            background: #fff;
            padding: 120px 40px 40px 40px;
            border-radius: 20px;
            box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 10px;
            margin-bottom: 0.8rem;
        }

        .testi-visa-type
        {
            background-color: $sec-color;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $pri-color;
            font-size: $sec-20px-size;
            border-radius: 0px 50px 50px 0px;
            
            font-size: $sec-18px-size;
            padding: 15px 25px;
            margin-bottom: 16px;
            position: absolute;
            left: 0;
            top: 40px;
            box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
            @include fraunces-font;
                font-weight: $font-semibold;


            span
            {
                margin-bottom: 0;
                line-height: normal;
                text-transform: capitalize;
                color: $pri-color;
                text-align: center;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
            }
        }

        .testi-cuatomer-name
        {
            h2 
            {
                @include fraunces-font;
                font-weight: $font-semibold;
                font-size: 24px;
            }

            span 
            {
                margin-left: 5px 
            }
        }

        .testi-content
        {
            p 
            {
                margin-bottom: 0px;
                font-family: 'SharpSansMedium';
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
        
    }
    a 
        {
            @include read-button;
            width: unset;
        }

    
}

.about-us-page
{

    .welcome-sec, .happyclients-sec
    {
        @include section-padding;

        .left-side
        {
      
            @include sec-left-side;
            height: 100%;

            .sec-title2, .sec-title3
            {
                h2 
                {
                    @include sec-main-title2;
                    margin-bottom: 0;
                    color: $pri-color;
                }
            }
            
            .sec-text
            {
                p
                {
                color: $pri-text-color;
                font-family: SharpSansMedium;
                }
            }
    
        }

        .right-sec
        {
            img 
            {
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .happyclients-sec 
    {
        
        .row 
        {
            flex-direction: row-reverse;
        }

        .left-side
        {
            margin-left: 30px;
        }

        .sec-title2
            {
                h2 
                {
                    font-size: 75px !important;
                }
            }
    }

    .our-journey
    {
        @include section-padding;
        background-color: #fff;

        .right-sec
        {
      
            @include sec-left-side;
            align-items: center;
            align-content: center;

            .sec-title
            {
                display: flex;
                align-items: center;
                align-content: center;
                flex-wrap: wrap;

                span
                {
                    @include sec-main-title;
                    @include letter-spacing;
                }
            }
            
            .sec-title2
            {
                h2 
                {
                    @include sec-main-title2;
                    margin-bottom: 0;
                    text-align: center;
                }
            }

            .sec-text
            {
                p
                {
                color: $pri-text-color;
                font-family: SharpSansMedium;
                text-align: center;
                }
            }
    
        }
    }
}

.about-us-page, .contact-us-page, .teams-policies-page, .service-page, .permanent-status-page, .corporate-services-page,
.testimonials-page, .client-portal-page, .free-assessment-page, .book-consultation-page
{
    .page-banner
    {
        background-color: $bg-pri-color;
        position: relative;
        padding: 80px 0px;

        &::before
            {
                content: '';
                background: url('./images/plan-fly-3.png') no-repeat;
                position: absolute;
                top: 0px;
                left: 110px;
                width: 300px;
                height: 210px;
                flex-shrink: 0;
            }

        .right-sec
        {
      
            @include sec-left-side;
            align-items: center;
            align-content: center;

            .sec-title
            {
                display: flex;
                align-items: center;
                align-content: center;
                flex-wrap: wrap;

                span
                {
                    @include sec-main-title;
                    @include letter-spacing;
                }
            }
            
            .sec-title2
            {
                h2 
                {
                    @include sec-main-title2;
                    margin-bottom: 0;
                    text-align: center;
                    color: #fff;
                }
            }
    
        }
    }
}

.contact-us-page
{

    .contact-form
{
    @include section-padding;

    .contact-card
    {
        background-color: #fff;
        border-radius: 26px;
        box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
    }

    .form-inner
    {
        padding: 50px 40px;

        h2 
        {
            @include fraunces-font;
            font-weight: $font-semibold;
            font-size: 30px;
            color: $pri-color;
            margin-bottom: 1rem;
        }

        p 
        {
            font-family: 'SharpSansMedium';
            margin-bottom: 1.5rem;
        }

        textarea 
        {
            height: 150px !important;
        }

        .form-control,.form-select
        {
            height: 48px;
            border: 1px solid rgba(164, 164, 164, 0.25);
            margin-bottom: 2rem;

            &:focus
            {
                border: 2px solid $pri-color;
                box-shadow: unset;
            }
        }

        button
        {
            @include read-button;
        }

        
    }

    iframe 
    {
        border-radius: 0pc 26px 26px 0px;
        height: 100%;  
    }
}

.contact-data

{
    padding: 0px 0px 80px 0px;
    .counters-div 
    {
        display: flex;
        gap: 1rem;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }

    .counters-icon 
    {
        width: 65px;
        height: 65px;
        flex-shrink: 0;
        background: #CADFED;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 3px 6px rgba(164, 164, 164, 0.25);
    }

    .conunters-nos
    {
        
        a, p
        {
            color: $pri-color;
            font-family: "SharpSansBold";
            text-underline-position: under;
            font-size: $sec-20px-size;
            margin-bottom: 0px;
        }
    }
}

}

.teams-policies-page
{
    .terms-data
    {
        @include section-padding;

        .terms-content
        {
            margin-bottom: 2.5rem;
            h2 
            {
                @include fraunces-font;
                color: $pri-color;
                font-size: 30px;
                font-weight: $font-bold;
                margin-bottom: 1rem;
            }

            p, li
            {
                font-family: 'SharpSansMedium';

                a 
                {
                    color: $pri-color;
                    text-underline-position: under;
                    font-family: 'SharpSansSemiBold';

                }
            }

        div 
        {
            margin-top: 3rem;
            .book-btn
            {
                @include read-button;
                margin-top: 2rem;
            }
        }

            
        }
    }
}

.service-page, .permanent-status-page, .corporate-services-page
{
    
    .all-services
    {
        .top-text
        {
            color: $pri-text-color;
            font-family: SharpSansMedium;
            margin: 50px 0px;
        }
        .service-details 
        {
            @include section-padding;
           
            .left-side
            {
                @include sec-left-side;
                
                .sec-title2
                {
                h2 
                {
                    @include sec-main-title2;
                    margin-bottom: 0;
                }
                }

                .sec-text
                {
                    p
                    {
                    color: $pri-text-color;
                    font-family: 'SharpSansMedium';
                    }
                }
            }

            .basic-eligibility
            {
                h3 
                {
                    font-family: "Fraunces", serif;
                    font-weight: 600;
                    font-size: 20px;
                    margin-bottom: 1rem;
                }
                .key-point1
                {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 0.5rem;
                    margin-bottom: 8px;
                    

                    svg 
                    {
                        background: #cadfed;
                        border-radius: 50%;
                        
                    }

                    p
                    {
                        margin-bottom: 0rem;
                        font-family: 'SharpSansMedium';
                    }

                    
                }
            }

            .book-button
            {
                margin-top: 1rem;
                a 
                {
                    @include primary-book-btn;
                    padding: 15px 40px;
                    color: $pri-color;
                    text-decoration: none;
                    background-color: $sec-color;
                    border: 1px solid $sec-color;

                    &:hover
                    {
                        background-color: transparent;
                        border: 1px solid $bg-pri-color;    
                    }
                }
    
                svg 
                {
                    margin-left: 7px;
                    transition: ease-in 0.2s;
                }
    
                &:hover
                {
                    color: $bg-pri-color;
    
                    svg 
                    {
                        transform: translate(4px, 0px);
                        path
                        {
                            fill: $bg-pri-color;
                        }
                    }
                }
                
            }

            ul 
            {
                padding-inline-start: 60px;

                li 
                {
                    margin-bottom: 0rem;
                font-family: 'SharpSansMedium';
                }
            }

            img 
            {
                width: 100%;
                object-fit: cover;
            }
        }
    }

}

.testimonials-page 
{
    .all-testimonials
    {
        @include section-padding;
    }
}

.client-portal-page
{
    .client-portal 
    {
        @include section-padding;
    }

    iframe 
    {
        width: 100%;
        height: 100vh;
    }
}

.free-assessment-page
{
    .all-assessment
    {
        @include section-padding;

        .counters-sec 
        {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            flex-wrap: wrap;
            z-index: 1;

            // &::after
            // {
            //     content: '';
            //     width: 408.327px;
            //     height: 316.05px;
            //     transform: rotate(1.98deg);
            //     background: url('./images/plan-fly-4.png') no-repeat;
            //     position: absolute;
            //     right: 28px;
            //     bottom: -28px;
            //     z-index: -1;
            // }
        

            .counters-div 
            {
                padding: 35px 55px 50px 55px;
                width: 50%;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                gap: 1.2rem;
                align-content: center;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;

                &:nth-child(1)
                {
                    border-radius: 20px 0px 0px 0px;
                    border-right: 1px solid #E8E8E8;
                    border-bottom: 1px solid #E8E8E8;
                }
                &:nth-child(2)
                {
                    border-radius: 0px 20px 0px 0px;
                    border-bottom: 1px solid #E8E8E8;
                    border-left: 1px solid #E8E8E8;
                }
                &:nth-child(3)
                {
                    border-radius: 0px 0px 0px 0px;
                    border-top: 1px solid #E8E8E8;
                    border-right: 1px solid #E8E8E8;
                    border-bottom: 1px solid #E8E8E8;
                }
                &:nth-child(4)
                {
                    border-radius: 0px 0px 0px 0px;
                    border-top: 1px solid #E8E8E8;
                    border-left: 1px solid #E8E8E8;
                    border-bottom: 1px solid #E8E8E8;
                }

                &:nth-child(5)
                {
                    border-radius: 0px 0px 0px 0px;
                    border-top: 1px solid #E8E8E8;
                    border-right: 1px solid #E8E8E8;
                    border-bottom: 1px solid #E8E8E8;
                }
                &:nth-child(6)
                {
                    border-radius: 0px 0px 0px 0px;
                    border-top: 1px solid #E8E8E8;
                    border-left: 1px solid #E8E8E8;
                    border-bottom: 1px solid #E8E8E8;
                }

                &:nth-child(7)
                {
                    border-radius: 0px 0px 0px 20px;
                    border-top: 1px solid #E8E8E8;
                    border-right: 1px solid #E8E8E8;
                    
                }
                &:nth-child(8)
                {
                    border-radius: 0px 0px 20px 0px;
                    border-top: 1px solid #E8E8E8;
                    border-left: 1px solid #E8E8E8;
                   
                }

                &::before
                {
                    content: '';
                    width: 3px;
                    height: 76px;
                    background:$pri-color;
                    position: absolute;
                    left: 0;
                }
            }

            .conunters-nos
            {
                

                h2 
                {
                    color: $pri-color;
                    @include fraunces-font;
                    line-height: 1.2;
                    font-size: 29px;
                    font-weight: $font-bold;
                    margin-bottom: 0px;
                    margin-bottom: 1rem;
                } 

                p 
                {
                    margin-bottom: 0px;
                    font-size: $sec-16px-size;
                    color: $pri-text-color;
                    font-family: 'SharpSansMedium';
                }
            }
        }
        .book-button
            {
                margin-top: 1rem;
                a 
                {
                    @include primary-book-btn;
                    padding: 15px 40px;
                    color: $pri-color;
                    text-decoration: none;
                    background-color: $sec-color;
                    border: 1px solid $sec-color;

                    &:hover
                    {
                        background-color: transparent;
                        border: 1px solid $bg-pri-color;    
                    }
                }
    
                svg 
                {
                    margin-left: 7px;
                    transition: ease-in 0.2s;
                }
    
                &:hover
                {
                    color: $bg-pri-color;
    
                    svg 
                    {
                        transform: translate(4px, 0px);
                        path
                        {
                            fill: $bg-pri-color;
                        }
                    }
                }
                
            }
    }
}

.book-consultation-page
{
    .book-consultation
    {
       

        iframe 
        {
            width: 100%;
            height: 1100px;
        }
    }
}

.modal
{
    background-color: rgba(0, 0, 0, 0.7);
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

.circle {
    width: 25px;
    height: 25px;
    background: linear-gradient(90deg, hsla(228, 53%, 21%, 1) 0%, hsla(228, 53%, 21%, 1) 12%, hsla(228, 51%, 37%, 1) 100%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.circle:before {
    content: '';
    width: 25px;
    height: 25px;
    background: linear-gradient($bg-pri-color, $bg-pri-color);
    border-radius: 50%;
    opacity: 0.7;
    animation: loading 1.3s;
    animation-iteration-count: infinite;
}

@keyframes loading {
    100% {
        transform: scale(2, 2);
        opacity: 0;
    }
}

.circle:nth-child(2):before {
    animation-delay: 0.3s;
}

.circle:nth-child(3):before {
    animation-delay: 0.6s;
}

.circle:nth-child(4):before {
    animation-delay: 0.9s;
}

.circle:nth-child(5):before {
    animation-delay: 1.2s;
}

.loading-text {
    width: 100%;
    position: absolute;
    top: 55%;
    left: 0%;
    text-align: center;
}

.modal 
{
    .modal-content 
    {
        padding: 1rem;
    }
    .modal-body
    {
    text-align: center;
        p 
        {
            font-family: 'SharpSansMedium';
        }
    }

    .tick-svg
    {
        text-align: center;
        margin: 1rem 1rem 2rem 1rem;
    }

    .modal-footer
    {   border-top: unset;
        justify-content: center;
        button 
        {
            @include read-button();
            width: unset;
            padding: 10px 30px;
        }
    }
}


@media only screen and (max-width: 576px)
{   
    body
    {
        overflow-x: hidden;
    }
    .navbar-nav .dropdown-item {
        width: 100%;
        padding: 0.8rem 0rem 0.8rem 1rem;
    }

    .navbar-nav .nav-item 
    {
        margin-bottom: 0.6rem;
    }

    .main-banner .banner-flex .sec-width {
        width: 100%;
    }
    .main-banner .right-sec::before {
        content: "";
        width: 200px;
        height: 145px;
        background-size: contain;
        left: 0px;
    }

    .main-banner .left-sec
    {
        display: none;
    }

    .main-banner .banner-flex {
        flex-wrap: wrap;
    }

    .main-banner .right-sec h1 {
        font-size: 2.8rem;
        font-weight: 600;
    }

    .main-banner .right-sec {
        padding: 140px 0px;
        
        margin-left: 15px;
    }

    .main-banner .left-sec img
    {
        height: unset;
    }

    .flex-custome-column-reverse
    {
        flex-direction: column-reverse;
    }
    
    .about-us,
    .our-success,
    .our-services,
    .why-choose-us,
    .our-work-process,
    .our-testi-slider,
    .main-footer {
        padding: 30px 0;
    }

    .about-us .left-side,
    .why-choose-us .left-side
    {
        margin-left: 0px;
        margin-bottom: 1.5rem;
    }

    .about-us .left-side .sec-title2 h2,
    .our-success .right-side .sec-title2 h2,
    .why-choose-us .left-side .sec-title2 h2,
    .our-work-process .right-side .sec-title2 h2,
    .our-testi-slider .right-side .sec-title2 h2
    {
        font-size: 2.4rem;
        line-height: 45px;
        text-align: left;
        width: 100%;
    }

    .about-us .left-side a
    {
        width: 100%;
        text-align: center;
    }

    .about-us .left-side .sec-title span,
    .our-success .right-side .sec-title span,
    .our-services .right-side .sec-title span,
    .why-choose-us .left-side .sec-title span,
    .our-work-process .right-side .sec-title span,
    .our-testi-slider .right-side .sec-title span,
    .about-us-page .our-journey .right-sec .sec-title span
    {
        font-size: 1rem;
        padding: 12px 26px;
    }

    .our-success .right-side
    {
        flex-wrap:nowrap;
    }

    .our-success .right-side .sec-text p
    {
        width: 100%;
    }

    .our-success .counters-sec .counters-div
    {
        width: 100%;
        padding: 30px 40px;
        gap: 2rem;
    }

    .our-services .our-services-slider .sliderCard .slideNumber {
        width: 55px;
        height: 55px;
    }

    .our-services .our-services-slider .sliderCard .card-details .visa-title,
    .our-services .our-services-slider .sliderCard .card-details .readmore a
    {
        font-size: 1rem;
    }
    .our-services .our-services-slider .sliderCard .card-details .readmore a
    {
        padding: 13px 39px;
    }


    .our-success .counters-sec .counters-icon {
        width: 75px;
        height: 75px;
    }

    .our-success .counters-sec .conunters-nos h2,
    .our-services .right-side .sec-title2 h2
    {
        font-size: 2.8rem;
        line-height: 1.2;
    }

    .our-success .counters-sec .conunters-nos p
    {
        font-size: 1rem;
    }

    .our-success .counters-sec .counters-div:nth-child(1) {
        border-radius: 20px 20px 0px 0px;
        border-right: unset;
        border-bottom: 1px solid #E8E8E8;
    }

    .our-success .counters-sec .counters-div:nth-child(2) {
        border-radius: unset;
        border-bottom: 1px solid #E8E8E8;
        border-left: unset;
    }

    .our-success .counters-sec .counters-div:nth-child(3) {
        border-radius: unset;
        border-top: unset;
        border-right: unset;
        border-bottom: 1px solid #E8E8E8;
    }

    .our-success .counters-sec .counters-div:nth-child(4) {
        border-radius: 0px 0px 20px 20px;
        border-top: unset;
        border-left: unset;
    }

    .our-success .counters-sec::after
    {
        width: 185.327px;
        height: 139.05px;
        bottom: -10%;
        right: 0%;
        background-size: cover;
        transform: rotate(69deg);
    }

    .our-services .right-side .sec-text p,
    .our-work-process .right-side .sec-text p
    {
        width: 100%;
        text-align: left;
    }

    .our-services .our-services-slider .prevarrow,
    .our-testi-slider .our-testi-slider1 .prevarrow {
        height: 50px;
        width: 50px;
        top: 104%;
        z-index: 99;
        bottom: -72px;
        left: 63%;
    }

    .our-services .our-services-slider .nextarrow,
    .our-testi-slider .our-testi-slider1 .nextarrow
    {
        height: 50px;
        width: 50px;
        top: 104%;
        z-index: 99;
        bottom: -72px;
        right: 7%;
    }

    .our-testi-slider .our-testi-slider1 .prevarrow {
        height: 50px;
        width: 50px;
        top: 100%;
        z-index: 99;
        bottom: -72px;
        left: 63%;
    }

     .our-testi-slider .our-testi-slider1 .nextarrow
    {
        height: 50px;
        width: 50px;
        top: 100%;
        z-index: 99;
        bottom: -72px;
        right: 7%;
    }

    .slick-slider
    {
        margin-bottom: 1rem;
    }

    .our-testi-slider1 .sliderCard
    {
        margin-bottom: 2rem;
    }

    .why-choose-us .left-side .key-points .key-point1 
    {
        width: 47%;
    }

    .our-work-process .process-steps-card,
    .our-testi-slider .our-testi-slider1 .testi-card
    {
        margin-top: 30px;
    }

    .our-work-process .process-steps-card,
    .our-testi-slider .our-testi-slider1 .testi-card
    {
        padding: 120px 30px 60px 30px;
    }

    .our-testi-slider .our-testi-slider1 .testi-content p {
        -webkit-line-clamp: 9;
    }

    .page-bottom .sec-content::before {
        left: 56%;
        width: 186px;
        height: 116px;
        background-size: contain;
    }

    .page-bottom .sec-content h2
    {
        font-size: 2.375rem;
        margin-bottom: 1.5rem;
    }

    .page-bottom .sec-content h3 a {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
    }

    .page-bottom .sec-content::after
    {
        width: 186px;
        height: 92px;
        background-size: contain;
        right: 50%;
    }

    .page-bottom .sec-content p
    {
        width: 100%;
    }

    .main-footer h4
    {
        margin: 1.5rem 0rem;
    }

    .mini-footer .dev-name
    {
        text-align: left;
        margin-top: 1rem;
    }

    .about-us-page, .contact-us-page, .teams-policies-page, .service-page, .permanent-status-page, .corporate-services-page,
    .testimonials-page, .client-portal-page, .free-assessment-page, .book-consultation-page
    {
        
        .page-banner
        {
            padding: 130px 0px 100px 0px;

            &::before
                {
                    content: '';
                    left: 0%;
                    width: 181px;
                    height: 189px;
                    background-size: contain;
                }

            .right-sec
            {
        
            

                .sec-title
                {
                    

                    span
                    {
                        font-size: 1rem;
                        text-align: center;
                    }
                }
                
                .sec-title2
                {
                    h2 
                    {
                        font-size: 2rem;
                        line-height: 45px;
                    }
                }
        
            }
        }
    }


    .all-testimonials, .free-assessment-page .all-assessment,
    .client-portal-page .client-portal, .about-us-page .welcome-sec, .about-us-page .happyclients-sec,
    .about-us-page .our-journey, .contact-us-page .contact-form
    {
        padding: 50px 0px;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div 
    {
        width: 100%;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div {
        padding: 33px 33px 50px 33px;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(1) {
        border-radius: 20px 20px 0px 0px;
        border-right: 0px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(2),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(3),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(4),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(5),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(6),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(7) {
        border-radius: 0px 0px 0px 0px;
        border-bottom: 1px solid #E8E8E8;
        border-top: 1px solid #E8E8E8;
        border-left: 0px solid #E8E8E8;
        border-right: 0px solid #E8E8E8;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(8)
    {
        border-radius: 0px 0px 20px 20px;
        border-left: 0px solid #E8E8E8;
    }

    .about-us-page .happyclients-sec .left-side {
        margin-left: 0px;
    }

    .about-us-page .happyclients-sec .sec-title2 h2 {
        font-size: 60px !important;
    }

    .about-us-page .happyclients-sec .left-side .sec-title3 h2
    {
        font-size: 2.8rem;
    }

    .about-us-page .happyclients-sec .left-side {
        gap: 15px;
    }

    .about-us-page .our-journey .right-sec .sec-title2 h2
    {
        font-size: 2rem;
        line-height: 40px;
    }

    .contact-us-page .contact-form .form-inner {
        padding: 40px 30px;
    }

    .contact-us-page .contact-form .form-inner button
    {
        width: 100%;
    }

    .contact-us-page .contact-form iframe {
        border-radius: 0pc 00px 26px 26px;
        height: 100%;
    }

    .contact-us-page .contact-data .counters-div
    {
        margin-bottom: 1.2rem;
    }

    .contact-us-page .contact-data .conunters-nos a, .contact-us-page .contact-data .conunters-nos p
    {
        font-size: 1rem;
    }

    .contact-us-page .contact-data .counters-icon {
        width: 55px;
        height: 55px;
    }

    .service-page .all-services .service-details .left-side .sec-title2 h2, 
    .permanent-status-page .all-services .service-details .left-side .sec-title2 h2, 
    .corporate-services-page .all-services .service-details .left-side .sec-title2 h2
    {
        font-size: 2.375rem;
        line-height: 45px;
    }

    .service-page .all-services .service-details .left-side, 
    .permanent-status-page .all-services .service-details .left-side, 
    .corporate-services-page .all-services .service-details .left-side
    {
        gap: 20px;
    }

    .service-page .all-services .service-details .basic-eligibility .key-point1 svg, 
    .permanent-status-page .all-services .service-details .basic-eligibility .key-point1 svg, 
    .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 svg {
        width: 25px;
        height: 25px;
    }

    .service-page .all-services .service-details .book-button, 
    .permanent-status-page .all-services .service-details .book-button, 
    .corporate-services-page .all-services .service-details .book-button {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .service-page .all-services .service-details, 
    .permanent-status-page .all-services .service-details, .corporate-services-page .all-services .service-details
    {
        padding: 30px 0px; 
    }

}

@media only screen and (min-width: 577px) and (max-width: 767px)
{

.navbar-nav .nav-item 
    {
        margin-bottom: 0.6rem;
    }
    .navbar-nav .dropdown-item {
        width: 100%;
        padding: 0.8rem 0rem 0.8rem 1rem;
    }
    .main-banner .banner-flex .sec-width {
        width: 100%;
    }
    .main-banner .right-sec::before {
        content: "";
        width: 213px;
        height: 157px;
        background-size: contain;
        left: 0px;
    }

    .main-banner .right-sec {
        margin-left: 15px;
    }

    .main-banner .left-sec
    {
        display: none;
    }

    .main-banner .banner-flex {
        flex-wrap: wrap;
        margin-left: 66px;
    }

    .main-banner .right-sec h1 {
        font-size: 2.8rem;
        font-weight: 600;
    }

    .main-banner .right-sec {
        padding: 150px 0px;
    }

    .main-banner .left-sec img
    {
        height: unset;
    }

    .flex-custome-column-reverse
    {
        flex-direction: column-reverse;
    }
    
    .about-us,
    .our-success,
    .our-services,
    .why-choose-us,
    .our-work-process,
    .our-testi-slider,
    .main-footer {
        padding: 50px 0;
    }

    .about-us .left-side,
    .why-choose-us .left-side
    {
        margin-left: 0px;
        margin-bottom: 1.5rem;
    }

    .about-us .left-side .sec-title2 h2,
    .our-success .right-side .sec-title2 h2,
    .why-choose-us .left-side .sec-title2 h2,
    .our-work-process .right-side .sec-title2 h2,
    .our-testi-slider .right-side .sec-title2 h2
    {
        font-size: 2.4rem;
        line-height: 45px;
        text-align: left;
        width: 100%;
    }

    .about-us .left-side a
    {
        width: 100%;
        text-align: center;
    }

    .about-us .left-side .sec-title span,
    .our-success .right-side .sec-title span,
    .our-services .right-side .sec-title span,
    .why-choose-us .left-side .sec-title span,
    .our-work-process .right-side .sec-title span,
    .our-testi-slider .right-side .sec-title span {
        font-size: 1rem;
        padding: 12px 26px;
    }

    .our-success .right-side
    {
        flex-wrap:nowrap;
    }

    .our-success .right-side .sec-text p
    {
        width: 100%;
    }

    .our-success .counters-sec .counters-div
    {
        width: 100%;
        padding: 30px 40px;
    }

    .our-services .our-services-slider .sliderCard .slideNumber {
        width: 55px;
        height: 55px;
    }

    .our-services .our-services-slider .sliderCard .card-details .visa-title,
    .our-services .our-services-slider .sliderCard .card-details .readmore a
    {
        font-size: 1rem;
    }
    .our-services .our-services-slider .sliderCard .card-details .readmore a
    {
        padding: 13px 39px;
    }


    .our-success .counters-sec .counters-icon {
        width: 75px;
        height: 75px;
    }

    .our-success .counters-sec .conunters-nos h2,
    .our-services .right-side .sec-title2 h2
    {
        font-size: 2.8rem;
        line-height: 1.2;
    }

    .our-success .counters-sec .conunters-nos p
    {
        font-size: 1rem;
    }

    .our-success .counters-sec .counters-div:nth-child(1) {
        border-radius: 20px 20px 0px 0px;
        border-right: unset;
        border-bottom: 1px solid #E8E8E8;
    }

    .our-success .counters-sec .counters-div:nth-child(2) {
        border-radius: unset;
        border-bottom: 1px solid #E8E8E8;
        border-left: unset;
    }

    .our-success .counters-sec .counters-div:nth-child(3) {
        border-radius: unset;
        border-top: unset;
        border-right: unset;
        border-bottom: 1px solid #E8E8E8;
    }

    .our-success .counters-sec .counters-div:nth-child(4) {
        border-radius: 0px 0px 20px 20px;
        border-top: unset;
        border-left: unset;
    }

    .our-success .counters-sec::after
    {
        width: 213.327px;
        height: 162.05px;
        bottom: -18%;
        background-size: cover;
        transform: rotate(169deg);
        right: -8%;
    }

    .our-services .right-side .sec-text p,
    .our-work-process .right-side .sec-text p
    {
        width: 100%;
        text-align: left;
    }

    .our-services .our-services-slider .prevarrow,
    .our-testi-slider .our-testi-slider1 .prevarrow {
        height: 50px;
        width: 50px;
        top: 104%;
        z-index: 99;
        bottom: -72px;
        left: 71%;
    }

    .our-services .our-services-slider .nextarrow,
    .our-testi-slider .our-testi-slider1 .nextarrow
    {
        height: 50px;
        width: 50px;
        top: 104%;
        z-index: 99;
        bottom: -72px;
        right: 7%;
    }

    .our-testi-slider .our-testi-slider1 .prevarrow {
        height: 50px;
        width: 50px;
        top: 100%;
        z-index: 99;
        bottom: -72px;
        left: 71%;
    }

     .our-testi-slider .our-testi-slider1 .nextarrow
    {
        height: 50px;
        width: 50px;
        top: 100%;
        z-index: 99;
        bottom: -72px;
        right: 7%;
    }

    .our-testi-slider .our-testi-slider1 .testi-content p {
        -webkit-line-clamp: 6;
    }

    .slick-slider
    {
        margin-bottom: 1rem;
    }

    .our-testi-slider1 .sliderCard
    {
        margin-bottom: 2rem;
    }

    .why-choose-us .left-side .key-points .key-point1 
    {
        width: 47%;
    }

    .our-work-process .process-steps-card,
    .our-testi-slider .our-testi-slider1 .testi-card
    {
        margin-top: 30px;
    }

    .our-work-process .process-steps-card,
    .our-testi-slider .our-testi-slider1 .testi-card
    {
        padding: 120px 30px 60px 30px;
    }

    .page-bottom .sec-content::before {
        left: 72%;
        width: 186px;
        height: 116px;
        background-size: contain;
    }

    .page-bottom .sec-content h2
    {
        font-size: 2.375rem;
        margin-bottom: 1.5rem;
    }

    .page-bottom .sec-content h3 a {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
    }

    .page-bottom .sec-content::after
    {
        width: 186px;
        height: 92px;
        background-size: contain;
        right: 69%;
    }

    .page-bottom .sec-content p
    {
        width: 100%;
    }

    .main-footer h4
    {
        margin: 1.5rem 0rem;
    }

    .mini-footer .dev-name
    {
        text-align: left;
        margin-top: 1rem;
    }

    .about-us-page, .contact-us-page, .teams-policies-page, .service-page, .permanent-status-page, .corporate-services-page,
    .testimonials-page, .client-portal-page, .free-assessment-page, .book-consultation-page
    {
        
        .page-banner
        {
            padding: 130px 0px 100px 0px;

            &::before
                {
                    left: 11%;
                    width: 181px;
                    height: 189px;
                    background-size: contain;
                }

            .right-sec
            {
        
            

                .sec-title
                {
                    

                    span
                    {
                        font-size: 1rem;
                        text-align: center;
                    }
                }
                
                .sec-title2
                {
                    h2 
                    {
                        font-size: 2rem;
                        line-height: 45px;
                    }
                }
        
            }
        }
    }


    .all-testimonials, .free-assessment-page .all-assessment,
    .client-portal-page .client-portal, .about-us-page .welcome-sec, .about-us-page .happyclients-sec,
    .about-us-page .our-journey, .contact-us-page .contact-form
    {
        padding: 50px 0px;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div 
    {
        width: 100%;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div {
        padding: 33px 33px 50px 33px;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(1) {
        border-radius: 20px 20px 0px 0px;
        border-right: 0px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(2),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(3),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(4),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(5),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(6),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(7) {
        border-radius: 0px 0px 0px 0px;
        border-bottom: 1px solid #E8E8E8;
        border-top: 1px solid #E8E8E8;
        border-left: 0px solid #E8E8E8;
        border-right: 0px solid #E8E8E8;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(8)
    {
        border-radius: 0px 0px 20px 20px;
        border-left: 0px solid #E8E8E8;
    }

    .about-us-page .happyclients-sec .left-side {
        margin-left: 0px;
    }

    .about-us-page .happyclients-sec .sec-title2 h2 {
        font-size: 60px !important;
    }

    .about-us-page .happyclients-sec .left-side .sec-title3 h2
    {
        font-size: 2.8rem;
    }

    .about-us-page .happyclients-sec .left-side {
        gap: 15px;
    }

    .about-us-page .our-journey .right-sec .sec-title2 h2
    {
        font-size: 2rem;
        line-height: 40px;
    }

    .contact-us-page .contact-form .form-inner {
        padding: 40px 30px;
    }

    .contact-us-page .contact-form .form-inner button
    {
        width: 100%;
    }

    .contact-us-page .contact-form iframe {
        border-radius: 0pc 00px 26px 26px;
        height: 100%;
    }

    .contact-us-page .contact-data .counters-div
    {
        margin-bottom: 1.2rem;
    }

    .service-page .all-services .service-details .left-side .sec-title2 h2, 
    .permanent-status-page .all-services .service-details .left-side .sec-title2 h2, 
    .corporate-services-page .all-services .service-details .left-side .sec-title2 h2
    {
        font-size: 2.375rem;
        line-height: 45px;
    }

    .service-page .all-services .service-details .left-side, 
    .permanent-status-page .all-services .service-details .left-side, 
    .corporate-services-page .all-services .service-details .left-side
    {
        gap: 20px;
    }

    .service-page .all-services .service-details .basic-eligibility .key-point1 svg, 
    .permanent-status-page .all-services .service-details .basic-eligibility .key-point1 svg, 
    .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 svg {
        width: 25px;
        height: 25px;
    }

    .service-page .all-services .service-details .book-button, 
    .permanent-status-page .all-services .service-details .book-button, 
    .corporate-services-page .all-services .service-details .book-button {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .service-page .all-services .service-details, 
    .permanent-status-page .all-services .service-details, .corporate-services-page .all-services .service-details
    {
        padding: 30px 0px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1023px)
{
    .navbar-nav .nav-item 
    {
        margin-bottom: 0.6rem;
    }
    .navbar-nav .dropdown-item {
        width: 100%;
        padding: 0.8rem 0rem 0.8rem 1rem;
    }
    .main-banner .banner-flex .sec-width {
        width: 100%;
    }
    .main-banner .right-sec {
        margin-left: 15px;
    }

    .main-banner .left-sec
    {
        display: none;
    }

    .main-banner .banner-flex {
        flex-wrap: wrap;
        margin-left: 66px;
    }

    .main-banner .right-sec h1 {
        font-size: 2.8rem;
        font-weight: 600;
    }

    .main-banner .right-sec {
        padding: 150px 0px;
    }

    .main-banner .left-sec img
    {
        height: unset;
    }



    .main-footer img {
        width: 52%;
    }
    
    .about-us,
    .our-success,
    .our-services,
    .why-choose-us,
    .our-work-process,
    .our-testi-slider,
    .main-footer {
        padding: 50px 0;
    }

    .about-us .left-side,
    .why-choose-us .left-side
    {
        margin-left: 0px;
        margin-bottom: 1.5rem;
    }

    .about-us .left-side .sec-title2 h2,
    .our-success .right-side .sec-title2 h2,
    .why-choose-us .left-side .sec-title2 h2,
    .our-work-process .right-side .sec-title2 h2,
    .our-testi-slider .right-side .sec-title2 h2
    {
        font-size: 2.4rem;
        line-height: 45px;
        text-align: left;
        width: 100%;
    }

    .about-us .left-side a
    {
        width: 100%;
        text-align: center;
    }

    .about-us .left-side .sec-title span,
    .our-success .right-side .sec-title span,
    .our-services .right-side .sec-title span,
    .why-choose-us .left-side .sec-title span,
    .our-work-process .right-side .sec-title span,
    .our-testi-slider .right-side .sec-title span {
        font-size: 1rem;
        padding: 12px 26px;
    }

    .our-success .right-side
    {
        flex-wrap:nowrap;
    }

    .our-success .right-side .sec-text p
    {
        width: 100%;
    }

    .our-success .counters-sec .counters-div
    {
        width: 50%;
        padding: 30px 40px;
        gap: 1.5rem;
    }

    .our-services .our-services-slider .sliderCard .slideNumber {
        width: 55px;
        height: 55px;
    }

    .our-services .our-services-slider .sliderCard .card-details
    {
        top: 66%
    }

    .our-services .our-services-slider .sliderCard .card-details .visa-title,
    .our-services .our-services-slider .sliderCard .card-details .readmore a
    {
        font-size: 1rem;
    }
    .our-services .our-services-slider .sliderCard .card-details .readmore a
    {
        padding: 13px 39px;
    }


    .our-success .counters-sec .counters-icon {
        width: 75px;
        height: 75px;
    }

    .our-success .counters-sec .conunters-nos h2, .our-services .right-side .sec-title2 h2 {
        font-size: 2.5rem;
        line-height: 1.2;
    }

    .our-success .counters-sec .conunters-nos p
    {
        font-size: 1rem;
    }

    .our-success .counters-sec .counters-div:nth-child(1) {
        border-radius: 20px 20px 0px 0px;
        border-right: unset;
        border-bottom: 1px solid #E8E8E8;
    }

    .our-success .counters-sec .counters-div:nth-child(2) {
        border-radius: unset;
        border-bottom: 1px solid #E8E8E8;
        border-left: unset;
    }

    .our-success .counters-sec .counters-div:nth-child(3) {
        border-radius: unset;
        border-top: unset;
        border-right: unset;
        border-bottom: 1px solid #E8E8E8;
    }

    .our-success .counters-sec .counters-div:nth-child(4) {
        border-radius: 0px 0px 20px 20px;
        border-top: unset;
        border-left: unset;
    }

    .our-success .counters-sec::after
    {
        width: 260.327px;
    height: 194.05px;
    bottom: -27%;
    background-size: cover;
    transform: rotate(177deg);
    right: 0;
    }

    .our-services .right-side .sec-text p,
    .our-work-process .right-side .sec-text p
    {
        width: 100%;
        text-align: left;
    }

    .our-services .our-services-slider .prevarrow,
    .our-testi-slider .our-testi-slider1 .prevarrow {
        height: 50px;
        width: 50px;
        top: 104%;
        z-index: 99;
        bottom: -72px;
        left: 82%;
    }

    .our-services .our-services-slider .nextarrow,
    .our-testi-slider .our-testi-slider1 .nextarrow
    {
        height: 50px;
        width: 50px;
        top: 104%;
        z-index: 99;
        bottom: -72px;
        right: 1%;
    }

    .our-testi-slider .our-testi-slider1 .prevarrow {
        height: 50px;
        width: 50px;
        top: 100%;
        z-index: 99;
        bottom: -72px;
        left: 81%;
    }

     .our-testi-slider .our-testi-slider1 .nextarrow
    {
        height: 50px;
        width: 50px;
        top: 100%;
        z-index: 99;
        bottom: -72px;
        right: 1%;
    }

    .our-testi-slider .our-testi-slider1 .testi-content p {
        -webkit-line-clamp: 6;
    }

    .slick-slider
    {
        margin-bottom: 1rem;
    }

    .our-testi-slider1 .sliderCard
    {
        margin-bottom: 2rem;
    }

    .why-choose-us .left-side .key-points .key-point1 
    {
        width: 47%;
    }

    .our-work-process .process-steps-card,
    .our-testi-slider .our-testi-slider1 .testi-card
    {
        margin-top: 30px;
    }

    .our-work-process .process-steps-card,
    .our-testi-slider .our-testi-slider1 .testi-card
    {
        padding: 120px 30px 60px 30px;
    }

    .page-bottom .sec-content::before {
        left: 75%;
        width: 186px;
        height: 116px;
        background-size: contain;
    }

    .page-bottom .sec-content h2
    {
        font-size: 2.375rem;
        margin-bottom: 1.5rem;
    }

    .page-bottom .sec-content h3 a {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
    }

    .page-bottom .sec-content::after
    {
        width: 186px;
        height: 92px;
        background-size: contain;
        right: 72%;
    }

    .page-bottom .sec-content p
    {
        width: 100%;
    }

    .main-footer h4
    {
        margin: 1.5rem 0rem;
    }

    .mini-footer .dev-name
    {
        text-align: left;
        margin-top: 1rem;
    }

    .about-us-page, .contact-us-page, .teams-policies-page, .service-page, .permanent-status-page, .corporate-services-page,
    .testimonials-page, .client-portal-page, .free-assessment-page, .book-consultation-page
    {
        
        .page-banner
        {
            padding: 130px 0px 100px 0px;

            &::before
                {
                    left: 3%;
                    width: 181px;
                    height: 189px;
                    background-size: contain;
                }

            .right-sec
            {
        
            

                .sec-title
                {
                    

                    span
                    {
                        font-size: 1rem;
                        text-align: center;
                    }
                }
                
                .sec-title2
                {
                    h2 
                    {
                        font-size: 2rem;
                        line-height: 45px;
                    }
                }
        
            }
        }
    }


    .all-testimonials, .free-assessment-page .all-assessment,
    .client-portal-page .client-portal, .about-us-page .welcome-sec, .about-us-page .happyclients-sec,
    .about-us-page .our-journey, .contact-us-page .contact-form
    {
        padding: 50px 0px;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div 
    {
        width: 100%;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div {
        padding: 33px 33px 50px 33px;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(1) {
        border-radius: 20px 20px 0px 0px;
        border-right: 0px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(2),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(3),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(4),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(5),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(6),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(7) {
        border-radius: 0px 0px 0px 0px;
        border-bottom: 1px solid #E8E8E8;
        border-top: 1px solid #E8E8E8;
        border-left: 0px solid #E8E8E8;
        border-right: 0px solid #E8E8E8;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(8)
    {
        border-radius: 0px 0px 20px 20px;
        border-left: 0px solid #E8E8E8;
    }

    .about-us-page .happyclients-sec .left-side {
        margin-left: 0px;
    }

    .about-us-page .happyclients-sec .sec-title2 h2 {
        font-size: 60px !important;
    }

    .about-us-page .happyclients-sec .left-side .sec-title3 h2
    {
        font-size: 2.8rem;
    }

    .about-us-page .happyclients-sec .left-side {
        gap: 15px;
    }

    .about-us-page .our-journey .right-sec .sec-title2 h2
    {
        font-size: 2rem;
        line-height: 40px;
    }

    .contact-us-page .contact-form .form-inner {
        padding: 40px 30px;
    }

    .contact-us-page .contact-form .form-inner button
    {
        width: 100%;
    }

    .contact-us-page .contact-form iframe {
        border-radius: 0pc 00px 26px 26px;
        height: 100%;
    }

    .contact-us-page .contact-data .counters-div
    {
        margin-bottom: 1.2rem;
    }

    .service-page .all-services .service-details .left-side .sec-title2 h2, 
    .permanent-status-page .all-services .service-details .left-side .sec-title2 h2, 
    .corporate-services-page .all-services .service-details .left-side .sec-title2 h2
    {
        font-size: 2.375rem;
        line-height: 45px;
    }

    .service-page .all-services .service-details .left-side, 
    .permanent-status-page .all-services .service-details .left-side, 
    .corporate-services-page .all-services .service-details .left-side
    {
        gap: 20px;
    }

    .service-page .all-services .service-details .basic-eligibility .key-point1 svg, 
    .permanent-status-page .all-services .service-details .basic-eligibility .key-point1 svg, 
    .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 svg {
        width: 25px;
        height: 25px;
    }

    .service-page .all-services .service-details .book-button, 
    .permanent-status-page .all-services .service-details .book-button, 
    .corporate-services-page .all-services .service-details .book-button {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .service-page .all-services .service-details, 
    .permanent-status-page .all-services .service-details, .corporate-services-page .all-services .service-details
    {
        padding: 30px 0px;
    }

    .main-footer .contactus-details p {
        margin-bottom: 0.8rem;
        word-break: break-word;
    }
    
}


@media only screen and (min-width: 1024px) and (max-width: 1279px)
{
    .main-banner .banner-flex .sec-width {
        width: 100%;
    }

    .main-banner .right-sec::before {
        content: "";
        width: 220px;
        height: 200px;
        background-size: contain;
        left: 0px;
    }

    .main-banner .right-sec {
        margin-left: 15px;
    }

    .main-banner .left-sec
    {
        display: none;
    }

    .main-banner .banner-flex {
        flex-wrap: wrap;
        margin-left: 66px;
    }

    .main-banner .right-sec h1 {
        font-size: 4.8rem;
        font-weight: 600;
    }

    .main-banner .right-sec {
        padding: 150px 0px;
    }

    .main-banner .left-sec img
    {
        height: unset;
    }

    .main-footer img {
        width: 52%;
    }
    
    .about-us,
    .our-success,
    .our-services,
    .why-choose-us,
    .our-work-process,
    .our-testi-slider,
    .main-footer {
        padding: 50px 0;
    }

    .about-us .left-side
    {
        margin: 0px 0px 3rem 0px;
    }

    .about-us .left-side a
    {
        width: 50%;
    }

    .about-us .left-side .sec-title2 h2,
    .why-choose-us .left-side .sec-title2 h2,
    .our-work-process .right-side .sec-title2 h2,
    .our-testi-slider .right-side .sec-title2 h2 {
        font-size: 2.8rem;
        line-height: 50px;
    }

    .our-testi-slider .our-testi-slider1 .testi-card {
        padding: 120px 40px 60px 40px;
    }

    .why-choose-us .left-side
    {
        margin-left: 0px;
    }

    .our-success .counters-sec .counters-div
    {
        width: 50%;
    }

    .our-success .counters-sec::after {
        content: "";
        width: 308.327px;
        height: 216.05px;
        bottom: -148px;
        background-size: cover;
        transform: rotate(188deg);
    }

    .our-services .right-side .sec-title2 h2
    {
        text-align: center;
    }

    .our-services .our-services-slider .prevarrow,
    .our-testi-slider .our-testi-slider1 .prevarrow {
        height: 50px;
        width: 50px;
        top: 104%;
        z-index: 99;
        bottom: -72px;
        left: 87%;
    }

    .our-services .our-services-slider .nextarrow,
    .our-testi-slider .our-testi-slider1 .nextarrow
    {
        height: 50px;
        width: 50px;
        top: 104%;
        z-index: 99;
        bottom: -72px;
        right: 1%;
    }

    .our-services .right-side .sec-text p
    {
        width: 100%;
    }

    .our-services .our-services-slider .sliderCard .card-details {
        top: 60%;
    }

    .our-services .our-services-slider .sliderCard .card-details .visa-title,
    .our-services .our-services-slider .sliderCard .card-details .readmore a
    {
        padding: 12px 41px;
    }

    .our-services .our-services-slider .sliderCard .slideNumber
    {
        height: 50px;
        width: 50px;
    }

    .why-choose-us .left-side .key-points
    {
        margin-bottom: 3rem;
    }
    
    .our-testi-slider .our-testi-slider1 .testi-content p
    {
        -webkit-line-clamp: 8;
    }

    .page-bottom
    {
        padding: 150px 0px;
    }

    .page-bottom .sec-content::before {
        left: 75%;
        width: 310px;
        height: 180px;
        background-size: contain;
    }

    .page-bottom .sec-content::after
    {
        width: 310px;
        height: 140px;
        background-size: contain;
        right: 65%;
    }

    .contact-us-page .contact-data .counters-div .conunters-nos
    {
        word-break: break-word;
    }

    .main-footer h4
    {
        margin-top: 1.5rem;
    }

    .about-us-page, .contact-us-page, .teams-policies-page, .service-page, .permanent-status-page, .corporate-services-page,
    .testimonials-page, .client-portal-page, .free-assessment-page, .book-consultation-page
    {
        
        .page-banner
        {
            padding: 130px 0px 100px 0px;

            &::before
                {
                    left: 3%;
                    width: 210px;
                    height: 220px ;
                    background-size: contain;
                }

            .right-sec
            {
        
            

                .sec-title
                {
                    

                    span
                    {
                        font-size: 1rem;
                        text-align: center;
                    }
                }
                
                .sec-title2
                {
                    h2 
                    {
                        font-size: 2.5rem;
                        line-height: 55px;
                    }
                }
        
            }
        }
    }


    .all-testimonials, .free-assessment-page .all-assessment,
    .client-portal-page .client-portal, .about-us-page .welcome-sec, .about-us-page .happyclients-sec,
    .about-us-page .our-journey, .contact-us-page .contact-form
    {
        padding: 50px 0px;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div 
    {
        width: 100%;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div {
        padding: 33px 33px 50px 33px;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(1) {
        border-radius: 20px 20px 0px 0px;
        border-right: 0px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(2),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(3),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(4),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(5),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(6),
    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(7) {
        border-radius: 0px 0px 0px 0px;
        border-bottom: 1px solid #E8E8E8;
        border-top: 1px solid #E8E8E8;
        border-left: 0px solid #E8E8E8;
        border-right: 0px solid #E8E8E8;
    }

    .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(8)
    {
        border-radius: 0px 0px 20px 20px;
        border-left: 0px solid #E8E8E8;
    }

    .about-us-page .happyclients-sec .left-side {
        margin-left: 0px;
    }

    .about-us-page .happyclients-sec .sec-title2 h2 {
        font-size: 60px !important;
    }

    .about-us-page .happyclients-sec .left-side .sec-title3 h2
    {
        font-size: 2.8rem;
    }

    .about-us-page .happyclients-sec .left-side {
        gap: 15px;
    }

    .about-us-page .our-journey .right-sec .sec-title2 h2
    {
        font-size: 2rem;
        line-height: 40px;
    }

    .contact-us-page .contact-form .form-inner {
        padding: 40px 30px;
    }

    .contact-us-page .contact-form .form-inner button
    {
        width: 100%;
    }

    .contact-us-page .contact-form iframe {
        border-radius: 0pc 00px 26px 26px;
        height: 100%;
    }

    .contact-us-page .contact-data .counters-div
    {
        margin-bottom: 1.2rem;
    }

    .service-page .all-services .service-details .left-side .sec-title2 h2, 
    .permanent-status-page .all-services .service-details .left-side .sec-title2 h2, 
    .corporate-services-page .all-services .service-details .left-side .sec-title2 h2
    {
        font-size: 2.8rem;
        line-height: 55px;
    }

    .service-page .all-services .service-details .left-side, 
    .permanent-status-page .all-services .service-details .left-side, 
    .corporate-services-page .all-services .service-details .left-side
    {
        gap: 20px;
    }

    .service-page .all-services .service-details .basic-eligibility .key-point1 svg, 
    .permanent-status-page .all-services .service-details .basic-eligibility .key-point1 svg, 
    .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 svg {
        width: 25px;
        height: 25px;
    }

    .service-page .all-services .service-details .book-button, 
    .permanent-status-page .all-services .service-details .book-button, 
    .corporate-services-page .all-services .service-details .book-button {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .service-page .all-services .service-details, 
    .permanent-status-page .all-services .service-details, .corporate-services-page .all-services .service-details
    {
        padding: 30px 0px;
    }
    

}

@media only screen and (min-width: 1280px) and (max-width: 1319px)
{
    .main-banner .right-sec {
        margin-left: 65px;
    }

    .main-banner .left-sec img {
        height: unset;
    }

    .about-us .left-side
    {
        margin-left: 20px;
    }

    .about-us .left-side a
    {
        width: 34%;
    }

    .our-success .counters-sec .counters-div
    {
        width: 42%;
    }

    .our-success .counters-sec::after {
        content: "";
        width: 312.327px;
        height: 216.05px;
        right: -4%;
        bottom: 10%;
    }

    .about-us,
    .our-success,
    .our-services,
    .why-choose-us,
    .our-work-process,
    .our-testi-slider,
    .main-footer {
        padding: 50px 0;
    }

    .our-testi-slider .our-testi-slider1 .testi-content p {
        -webkit-line-clamp: 6;
    }

    .page-bottom {
        padding: 150px 0px;
    }

    .our-services .our-services-slider .sliderCard .card-details {
        position: absolute;
        top: 65%;
    }

}

@media only screen and (min-width: 1360px) and (max-width: 1439px)
{

    .main-banner .left-sec img {
        height: unset;
    }

    .about-us .left-side
    {
        margin-left: 20px;
    }

    .about-us .left-side a
    {
        width: 34%;
    }

    .our-success .counters-sec .counters-div
    {
        width: 42%;
    }

    .our-success .counters-sec::after {
        content: "";
        width: 312.327px;
        height: 216.05px;
        right: -4%;
        bottom: 10%;
    }

    .about-us,
    .our-success,
    .our-services,
    .why-choose-us,
    .our-work-process,
    .our-testi-slider,
    .main-footer {
        padding: 60px 0;
    }

    .our-testi-slider .our-testi-slider1 .testi-content p {
        -webkit-line-clamp: 6;
    }

    .page-bottom {
        padding: 150px 0px;
    }

}
@media only screen and (min-width: 1440px) and (max-width: 1535px)
{
    .main-banner .right-sec {
        margin-left: 59px;
    }

    .our-services .our-services-slider .prevarrow {
    
        height: 59px;
        width: 59px;
        left: -55px;

    }

    .our-services .our-services-slider .nextarrow
    {
        height: 59px;
        width: 59px;
        right: -55px;
    }

    .our-services .our-services-slider .prevarrow{
    
        height: 59px;
        width: 59px;
        left: -55px;
    }

    .our-services .our-services-slider .nextarrow
    {
        height: 59px;
        width: 59px;
        right: -55px;
    }

    .our-testi-slider .our-testi-slider1 .prevarrow {
    
        height: 59px;
        width: 59px;
        left: -55px;
        top: 54%;
    }

    .our-testi-slider .our-testi-slider1 .nextarrow
    {
        height: 59px;
        width: 59px;
        right: -55px;
        top: 54%;
    }

}

@media only screen and (min-width: 1600px) and (max-width: 1720px)
{
    .main-banner .right-sec {
        margin-left: 134px;
    }

    .about-us-page, .contact-us-page, .teams-policies-page, .service-page, .permanent-status-page, .corporate-services-page,
    .testimonials-page, .client-portal-page, .free-assessment-page, .book-consultation-page
    {
        
        .page-banner
        {

            &::before
                {
                    left: 9%;
                    width: 210px;
                    height: 220px ;
                    background-size: contain;
                }
        }
    }


}


@media only screen and (min-width: 1920px) and (max-width: 2023px)
{
    .main-banner .right-sec {
        margin-left: 300px;
    }

    .about-us-page, .contact-us-page, .teams-policies-page, .service-page, .permanent-status-page, .corporate-services-page,
    .testimonials-page, .client-portal-page, .free-assessment-page, .book-consultation-page
    {
        
        .page-banner
        {

            &::before
                {
                    left: 16%;
                    width: 210px;
                    height: 220px ;
                    background-size: contain;
                }
        }
    }
}

@media only screen and (min-width: 2024px) and (max-width: 2048px)
{
    .main-banner .right-sec {
        margin-left: 350px;
    }

    .about-us-page, .contact-us-page, .teams-policies-page, .service-page, .permanent-status-page, .corporate-services-page,
    .testimonials-page, .client-portal-page, .free-assessment-page, .book-consultation-page
    {
        
        .page-banner
        {

            &::before
                {
                    left: 18%;
                    width: 210px;
                    height: 220px ;
                    background-size: contain;
                }
        }
    }
}