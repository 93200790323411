@import url("https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,100;9..144,200;9..144,300;9..144,400;9..144,500;9..144,600;9..144,700;9..144,800;9..144,900&display=swap");
@font-face {
  font-family: SharpSansRegular;
  src: url(./fonts/SharpSans.otf);
}
@font-face {
  font-family: SharpSansThin;
  src: url(./fonts/SharpSansThin.otf);
}
@font-face {
  font-family: SharpSansLight;
  src: url(./fonts/SharpSansLight.otf);
}
@font-face {
  font-family: SharpSansMedium;
  src: url(./fonts/SharpSansMedium.otf);
}
@font-face {
  font-family: SharpSansSemibold;
  src: url(./fonts/SharpSansSemibold.otf);
}
@font-face {
  font-family: SharpSansBold;
  src: url(./fonts/SharpSansBold.otf);
}
@font-face {
  font-family: SharpSansExtrabold;
  src: url(./fonts/SharpSansExtrabold.otf);
}
* {
  scroll-behavior: smooth;
}

html {
  overflow-x: hidden;
}

body {
  font-family: "SharpSansRegular" !important;
  margin: 0px;
  padding: 0px;
  color: #222222;
  box-sizing: border-box;
  line-height: 25px !important;
  font-size: 1rem;
  background-color: #FAFAFA;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #192451;
}

::-webkit-scrollbar-thumb:hover {
  background: #192451;
}

.scroll-up-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #CADFED;
  position: fixed;
  height: 55px;
  width: 55px;
  background: #CADFED;
  right: 1.875rem;
  bottom: 1.875rem;
  text-align: center;
  line-height: 2.813rem;
  color: #fff;
  z-index: 9999;
  font-size: 1.875rem;
  border-radius: 50%;
  border-bottom-width: 2px;
  cursor: pointer;
  transition: 0.4s;
}
.scroll-up-btn:hover {
  background: #192451;
  border: 1px solid #192451;
}
.scroll-up-btn:hover svg path {
  fill: #CADFED;
}

.navbar {
  font-family: "SharpSansSemibold";
  background-color: #192451;
}
.navbar .logo {
  height: 3.75rem;
  width: 7.688rem;
  -o-object-fit: fill;
     object-fit: fill;
}
.navbar .nav-item {
  margin: 0px 5px;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: #192451 !important;
  background: #CADFED;
  border-radius: 6px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding-right: 0.7rem !important;
  padding-left: 0.7rem !important;
}

.navbar .book-consultant-btn {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  color: #192451 !important;
}
.navbar .book-consultant-btn:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.navbar .book-consultant-btn:hover {
  color: #FAFAFA !important;
}

.navbar-nav .dropdown-item {
  width: 100%;
  padding: 0.55rem 1.5rem;
}
.navbar-nav .dropdown-item:focus, .navbar-nav .dropdown-item:hover {
  color: #192451;
  background-color: #CADFED;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: #CADFED;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 1px #fff;
}

.navbar-toggler {
  border: unset;
}

.main-footer {
  padding: 4.25rem 0rem;
  border-bottom: 1px solid rgba(67, 67, 67, 0.37);
}
.main-footer h5 {
  font-size: 1rem;
  font-family: "SharpSansMedium";
  text-align: left;
  line-height: 1.5 !important;
  color: #222222;
  padding-bottom: 10px;
  margin: 0rem 0rem;
}
.main-footer img {
  width: 80%;
  -o-object-fit: cover;
     object-fit: cover;
  padding-bottom: 10px;
  float: left;
}
.main-footer h4 {
  font-family: "SharpSansBold";
  font-size: 1.25rem;
  color: #192451;
  text-align: left;
  margin-bottom: 1.5rem;
}
.main-footer .links-titles {
  padding-inline-start: 0px;
  list-style-type: none;
  margin-block-start: 0rem;
  margin-block-end: 0rem;
  text-align: left;
}
.main-footer .links-titles li {
  margin-bottom: 0.8rem;
}
.main-footer .links-titles li a {
  text-decoration: none;
  color: #222222;
  font-family: "SharpSansMedium";
  transition: ease-in 0.4s;
}
.main-footer .links-titles li a:hover {
  text-decoration: underline;
  text-underline-position: under;
}
.main-footer .contactus-details {
  font-family: "SharpSansMedium";
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: flex-start;
}
.main-footer .contactus-details:nth-child(5) {
  margin-bottom: 1.8rem;
}
.main-footer .contactus-details p {
  margin-bottom: 0.8rem;
}
.main-footer .contactus-details a {
  color: #222222;
  font-family: "SharpSansMedium";
  text-underline-position: under;
}
.main-footer .social-icons .d-flex {
  gap: 0.5rem;
}
.main-footer .social-icons .icon-bg {
  background-color: #CADFED;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  cursor: pointer;
  transition: 0.4s;
}
.main-footer .social-icons .icon-bg:hover {
  background: #192451;
  border: 1px solid #192451;
}
.main-footer .social-icons .icon-bg:hover svg path {
  fill: #CADFED;
}

.mini-footer {
  padding: 25px 0px;
}
.mini-footer .copy-right, .mini-footer .dev-name {
  font-family: "Fraunces", serif;
  color: #192451;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0rem;
}
.mini-footer .copy-right {
  text-align: left;
}
.mini-footer .dev-name {
  text-align: right;
}
.mini-footer .dev-name a {
  color: #192451;
  text-underline-position: under;
}

.page-bottom {
  background-color: #192451;
  padding: 108px 0px;
  position: relative;
}
.page-bottom .sec-content {
  text-align: center;
}
.page-bottom .sec-content h2 {
  font-family: "Fraunces", serif;
  color: #fff;
  font-size: 3.375rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.page-bottom .sec-content p {
  color: #fff;
  width: 60%;
  margin: 0px auto;
  padding: 0 0 1rem 0;
}
.page-bottom .sec-content h3 a {
  font-family: "Fraunces", serif;
  font-size: 34px;
  color: #fff;
  font-weight: 700;
  text-underline-position: under;
}
.page-bottom .sec-content::before {
  content: "";
  background: url("./images/plan-fly-2.png") no-repeat;
  position: absolute;
  top: 0;
  left: 75%;
  width: 286px;
  height: 216px;
}
.page-bottom .sec-content::after {
  content: "";
  background: url("./images/plan-fly-1.png") no-repeat;
  position: absolute;
  bottom: 0;
  right: 79%;
  width: 263px;
  height: 161px;
}

.main-banner {
  background-color: #192451;
  position: relative;
}
.main-banner .right-sec {
  padding: 150px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.main-banner .right-sec::before {
  content: "";
  background: url("./images/plan-fly-3.png") no-repeat;
  position: absolute;
  top: 0;
  width: 300px;
  height: 210px;
  flex-shrink: 0;
}
.main-banner .right-sec h1 {
  color: #fff;
  font-family: "Fraunces", serif;
  font-size: 4.063rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.main-banner .right-sec h1 span {
  font-weight: 300;
}
.main-banner .right-sec .key-point1 {
  display: flex;
  color: #fff;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.3rem;
}
.main-banner .right-sec .key-point1 div {
  width: 30px;
  height: 30px;
}
.main-banner .right-sec .key-point1 p {
  margin-bottom: 0rem;
}
.main-banner .right-sec .btn-padding {
  padding-top: 60px;
}
.main-banner .right-sec .book-button {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  padding: 15px 40px;
  color: #192451;
  text-decoration: none;
}
.main-banner .right-sec .book-button:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.main-banner .right-sec .book-button svg {
  margin-left: 7px;
  transition: ease-in 0.2s;
}
.main-banner .right-sec .book-button:hover {
  color: #fff;
}
.main-banner .right-sec .book-button:hover svg {
  transform: translate(4px, 0px);
}
.main-banner .right-sec .book-button:hover svg path {
  fill: #fff;
}
.main-banner .banner-flex {
  display: flex;
  justify-content: space-between;
}
.main-banner .banner-flex .sec-width {
  width: 50%;
}
.main-banner .right-sec {
  margin-left: 100px;
}
.main-banner .left-sec img {
  width: 100%;
}

.about-us {
  padding: 80px 0;
}
.about-us .right-side img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.about-us .left-side {
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-left: 50px;
}
.about-us .left-side .sec-title {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.about-us .left-side .sec-title span {
  color: #192451;
  font-size: 1.125rem;
  font-family: "SharpSansBold";
  background-color: #CADFED;
  border-radius: 30px;
  padding: 15px 30px;
  text-transform: capitalize;
  letter-spacing: 0.72px;
}
.about-us .left-side a {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  padding: 15px 40px;
  color: #fff;
  text-decoration: none;
  background-color: #192451;
  border: 1px solid #192451;
  width: 29%;
}
.about-us .left-side a:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.about-us .left-side a svg {
  margin-left: 7px;
  transition: ease-in 0.2s;
}
.about-us .left-side a svg path {
  fill: #fff;
}
.about-us .left-side a:hover {
  color: #192451;
  background-color: transparent;
  border: 1px solid #192451;
}
.about-us .left-side a:hover svg {
  transform: translate(4px, 0px);
}
.about-us .left-side a:hover svg path {
  fill: #192451;
}
.about-us .left-side .sec-title2 h2 {
  color: #192451;
  font-size: 3.375rem;
  font-family: "Fraunces", serif;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.about-us .left-side .sec-text p {
  color: #222222;
  font-family: SharpSansMedium;
}

.our-success {
  padding: 80px 0;
}
.our-success .right-side {
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  position: relative;
}
.our-success .right-side .sec-title {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.our-success .right-side .sec-title span {
  color: #192451;
  font-size: 1.125rem;
  font-family: "SharpSansBold";
  background-color: #CADFED;
  border-radius: 30px;
  padding: 15px 30px;
  text-transform: capitalize;
  letter-spacing: 0.72px;
}
.our-success .right-side .sec-title2 h2 {
  color: #192451;
  font-size: 3.375rem;
  font-family: "Fraunces", serif;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.our-success .right-side .sec-text p {
  color: #222222;
  font-family: "SharpSansMedium";
  width: 70%;
}
.our-success .counters-sec {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  z-index: 1;
}
.our-success .counters-sec::after {
  content: "";
  width: 408.327px;
  height: 316.05px;
  transform: rotate(1.98deg);
  background: url("./images/plan-fly-4.png") no-repeat;
  position: absolute;
  right: 28px;
  bottom: -28px;
  z-index: -1;
}
.our-success .counters-sec .counters-div {
  padding: 35px 55px;
  width: 35%;
  background-color: #fff;
  display: flex;
  gap: 3.5rem;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.our-success .counters-sec .counters-div:nth-child(1) {
  border-radius: 20px 0px 0px 0px;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
}
.our-success .counters-sec .counters-div:nth-child(2) {
  border-radius: 0px 20px 0px 0px;
  border-bottom: 1px solid #E8E8E8;
  border-left: 1px solid #E8E8E8;
}
.our-success .counters-sec .counters-div:nth-child(3) {
  border-radius: 0px 0px 0px 20px;
  border-top: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
}
.our-success .counters-sec .counters-div:nth-child(4) {
  border-radius: 0px 0px 20px 0px;
  border-top: 1px solid #E8E8E8;
  border-left: 1px solid #E8E8E8;
}
.our-success .counters-sec .counters-div::before {
  content: "";
  width: 3px;
  height: 76px;
  background: #192451;
  position: absolute;
  left: 0;
}
.our-success .counters-sec .counters-icon {
  width: 85px;
  height: 85px;
  flex-shrink: 0;
  background: #CADFED;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(164, 164, 164, 0.25);
}
.our-success .counters-sec .conunters-nos h2 {
  color: #192451;
  font-family: "Fraunces", serif;
  line-height: 70px;
  font-size: 3.125rem;
  font-weight: 700;
  margin-bottom: 0px;
}
.our-success .counters-sec .conunters-nos p {
  margin-bottom: 0px;
  font-size: 1.125rem;
  color: #222222;
  font-family: "SharpSansMedium";
}

.our-services {
  padding: 80px 0;
}
.our-services .right-side {
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  position: relative;
}
.our-services .right-side .sec-title {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.our-services .right-side .sec-title span {
  color: #192451;
  font-size: 1.125rem;
  font-family: "SharpSansBold";
  background-color: #CADFED;
  border-radius: 30px;
  padding: 15px 30px;
  text-transform: capitalize;
  letter-spacing: 0.72px;
}
.our-services .right-side .sec-title2 h2 {
  color: #192451;
  font-size: 3.375rem;
  font-family: "Fraunces", serif;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  margin-bottom: 0;
  text-transform: capitalize;
}
.our-services .right-side .sec-text p {
  color: #222222;
  font-family: "SharpSansMedium";
  text-align: center;
  width: 60%;
  margin: 0 auto;
}
.our-services .our-services-slider {
  padding-top: 50px;
}
.our-services .our-services-slider .sliderCard {
  position: relative;
  box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
  border-radius: 20px;
  margin-bottom: 0.8rem;
}
.our-services .our-services-slider .sliderCard img {
  width: 100%;
  border-radius: 22px;
  -o-object-fit: cover;
     object-fit: cover;
}
.our-services .our-services-slider .sliderCard .slideNumber {
  position: absolute;
  top: 4%;
  left: 20px;
  width: 69px;
  height: 69px;
  background-color: #CADFED;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Fraunces", serif;
  font-weight: 700;
  color: #192451;
  font-size: 1.25rem;
}
.our-services .our-services-slider .sliderCard .card-details {
  position: absolute;
  top: 70%;
}
.our-services .our-services-slider .sliderCard .card-details .visa-title {
  background-color: #CADFED;
  color: #192451;
  font-size: 1.25rem;
  border-radius: 0px 22px 22px 0px;
  font-family: "SharpSansBold";
  font-size: 1.125rem;
  padding: 15px 30px;
  margin-bottom: 16px;
  text-align: center;
}
.our-services .our-services-slider .sliderCard .card-details .visa-title p {
  margin-bottom: 0;
  line-height: normal;
  text-transform: capitalize;
}
.our-services .our-services-slider .sliderCard .card-details .readmore a {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  padding: 15px 40px;
  color: #fff;
  text-decoration: none;
  background-color: #192451;
  border: 1px solid #192451;
  width: 29%;
  background-color: #CADFED;
  width: unset;
  color: #192451;
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 0px 22px 22px 0px;
  font-family: "SharpSansBold";
  font-size: 1.125rem;
  padding: 13px 39px;
  margin-bottom: 16px;
  border: 1px solid #CADFED;
  transition: ease-in 0.2s;
}
.our-services .our-services-slider .sliderCard .card-details .readmore a:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.our-services .our-services-slider .sliderCard .card-details .readmore a svg {
  margin-left: 7px;
  transition: ease-in 0.2s;
}
.our-services .our-services-slider .sliderCard .card-details .readmore a svg path {
  fill: #fff;
}
.our-services .our-services-slider .sliderCard .card-details .readmore a:hover {
  color: #192451;
  background-color: transparent;
  border: 1px solid #192451;
}
.our-services .our-services-slider .sliderCard .card-details .readmore a:hover svg {
  transform: translate(4px, 0px);
}
.our-services .our-services-slider .sliderCard .card-details .readmore a:hover svg path {
  fill: #192451;
}
.our-services .our-services-slider .sliderCard .card-details .readmore a:hover {
  border-left: 0px;
  background-color: #192451;
  color: #FAFAFA;
}
.our-services .our-services-slider .sliderCard .card-details .readmore a:hover svg path {
  fill: #FAFAFA;
}
.our-services .our-services-slider .sliderCard .card-details .readmore a svg path {
  fill: #192451;
}
.our-services .our-services-slider .slick-slide > div {
  margin: 0 10px;
}
.our-services .our-services-slider .slick-prev, .our-services .our-services-slider .slick-next {
  width: 69px;
  height: 69px;
}
.our-services .our-services-slider .slick-prev:before, .our-services .our-services-slider .slick-next:before {
  opacity: 1;
  color: #CADFED;
  font-size: 63px;
}
.our-services .our-services-slider .slick-prev {
  left: -70px;
}
.our-services .our-services-slider .slick-next {
  right: -70px;
}
.our-services .our-services-slider .nextarrow {
  background-image: url("./images/arrow-sm.svg");
  background-repeat: no-repeat;
  height: 69px;
  width: 69px;
  background-color: #CADFED;
  border: 1px solid #CADFED;
  border-radius: 50%;
  display: flex;
  background-position: center;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0px;
  right: -70px;
  cursor: pointer;
  transition: 0.4s ease-out;
}
.our-services .our-services-slider .nextarrow:hover {
  background-image: url("./images/arrow-sm-white.svg") !important;
  background-repeat: no-repeat !important;
  display: flex !important;
  background-position: center !important;
  background: #192451;
  border: 1px solid #192451;
}
.our-services .our-services-slider .nextarrow:hover svg path {
  fill: #CADFED;
}
.our-services .our-services-slider .prevarrow {
  background-image: url("./images/arrow-sm-left.svg");
  background-repeat: no-repeat;
  height: 69px;
  width: 69px;
  background-color: #CADFED;
  border: 1px solid #CADFED;
  border-radius: 50%;
  display: flex;
  background-position: center;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0px;
  left: -70px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
.our-services .our-services-slider .prevarrow:hover {
  background-image: url("./images/arrow-sm-left-white.svg") !important;
  background-repeat: no-repeat !important;
  display: flex !important;
  background-position: center !important;
  background: #192451;
  border: 1px solid #192451;
}
.our-services .our-services-slider .prevarrow:hover svg path {
  fill: #CADFED;
}

.why-choose-us {
  padding: 80px 0;
}
.why-choose-us .right-side img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.why-choose-us .left-side {
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-left: 50px;
}
.why-choose-us .left-side .sec-title {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.why-choose-us .left-side .sec-title span {
  color: #192451;
  font-size: 1.125rem;
  font-family: "SharpSansBold";
  background-color: #CADFED;
  border-radius: 30px;
  padding: 15px 30px;
  text-transform: capitalize;
  letter-spacing: 0.72px;
}
.why-choose-us .left-side a {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  padding: 15px 40px;
  color: #fff;
  text-decoration: none;
  background-color: #192451;
  border: 1px solid #192451;
  width: 29%;
}
.why-choose-us .left-side a:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.why-choose-us .left-side a svg {
  margin-left: 7px;
  transition: ease-in 0.2s;
}
.why-choose-us .left-side a svg path {
  fill: #fff;
}
.why-choose-us .left-side a:hover {
  color: #192451;
  background-color: transparent;
  border: 1px solid #192451;
}
.why-choose-us .left-side a:hover svg {
  transform: translate(4px, 0px);
}
.why-choose-us .left-side a:hover svg path {
  fill: #192451;
}
.why-choose-us .left-side .sec-title2 h2 {
  color: #192451;
  font-size: 3.375rem;
  font-family: "Fraunces", serif;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  margin-bottom: 0;
  text-transform: capitalize;
}
.why-choose-us .left-side .sec-text p {
  color: #222222;
  font-family: SharpSansMedium;
}
.why-choose-us .left-side .key-points {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.why-choose-us .left-side .key-points .key-point1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: 34%;
}
.why-choose-us .left-side .key-points .key-point1 svg {
  background: #cadfed;
  border-radius: 50%;
}
.why-choose-us .left-side .key-points .key-point1 p {
  margin-bottom: 0rem;
  font-family: "SharpSansSemiBold";
}

.our-work-process {
  padding: 80px 0;
}
.our-work-process .right-side {
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  position: relative;
}
.our-work-process .right-side .sec-title {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.our-work-process .right-side .sec-title span {
  color: #192451;
  font-size: 1.125rem;
  font-family: "SharpSansBold";
  background-color: #CADFED;
  border-radius: 30px;
  padding: 15px 30px;
  text-transform: capitalize;
  letter-spacing: 0.72px;
}
.our-work-process .right-side .sec-title2 h2 {
  color: #192451;
  font-size: 3.375rem;
  font-family: "Fraunces", serif;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.our-work-process .right-side .sec-text p {
  color: #222222;
  font-family: "SharpSansMedium";
  text-align: center;
  margin: 0 auto;
}
.our-work-process .process-steps-card {
  margin-top: 50px;
  position: relative;
  background: #fff;
  padding: 120px 40px 60px 40px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}
.our-work-process .process-steps-card .steps-nos {
  background-color: #CADFED;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #192451;
  font-size: 1.25rem;
  border-radius: 0px 50px 50px 0px;
  font-size: 1.125rem;
  padding: 10px 10px 10px 40px;
  margin-bottom: 16px;
  position: absolute;
  left: 0;
  top: 40px;
  box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
  font-family: "Fraunces", serif;
  font-weight: 600;
}
.our-work-process .process-steps-card .steps-nos span {
  margin-bottom: 0;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: capitalize;
  background-color: #192451;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.our-work-process .process-steps-card .steps-title h2 {
  font-family: "Fraunces", serif;
  font-weight: 600;
  font-size: 24px;
}
.our-work-process .process-steps-card .steps-content p {
  margin-bottom: 0px;
  font-family: "SharpSansMedium";
}

.our-testi-slider {
  padding: 80px 0;
}
.our-testi-slider .right-side {
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  position: relative;
}
.our-testi-slider .right-side .sec-title {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.our-testi-slider .right-side .sec-title span {
  color: #192451;
  font-size: 1.125rem;
  font-family: "SharpSansBold";
  background-color: #CADFED;
  border-radius: 30px;
  padding: 15px 30px;
  text-transform: capitalize;
  letter-spacing: 0.72px;
}
.our-testi-slider .right-side .sec-title2 h2 {
  color: #192451;
  font-size: 3.375rem;
  font-family: "Fraunces", serif;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  margin-bottom: 0;
  text-align: center;
  width: 80%;
  margin: 0px auto;
}
.our-testi-slider .our-testi-slider1 {
  margin-top: 20px;
}
.our-testi-slider .our-testi-slider1 .nextarrow {
  background-image: url("./images/arrow-sm.svg");
  background-repeat: no-repeat;
  height: 69px;
  width: 69px;
  background-color: #CADFED;
  border: 1px solid #CADFED;
  border-radius: 50%;
  display: flex;
  background-position: center;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0px;
  right: -70px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
.our-testi-slider .our-testi-slider1 .nextarrow:hover {
  background-image: url("./images/arrow-sm-white.svg") !important;
  background-repeat: no-repeat !important;
  display: flex !important;
  background-position: center !important;
  background: #192451;
  border: 1px solid #192451;
}
.our-testi-slider .our-testi-slider1 .nextarrow:hover svg path {
  fill: #CADFED;
}
.our-testi-slider .our-testi-slider1 .prevarrow {
  background-image: url("./images/arrow-sm-left.svg");
  background-repeat: no-repeat;
  height: 69px;
  width: 69px;
  background-color: #CADFED;
  border: 1px solid #CADFED;
  border-radius: 50%;
  display: flex;
  background-position: center;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0px;
  left: -70px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
.our-testi-slider .our-testi-slider1 .prevarrow:hover {
  background-image: url("./images/arrow-sm-left-white.svg") !important;
  background-repeat: no-repeat !important;
  display: flex !important;
  background-position: center !important;
  background: #192451;
  border: 1px solid #192451;
}
.our-testi-slider .our-testi-slider1 .prevarrow:hover svg path {
  fill: #CADFED;
}
.our-testi-slider .our-testi-slider1 .slick-slide > div {
  margin: 0 10px;
}
.our-testi-slider .our-testi-slider1 .testi-card {
  margin-top: 50px;
  position: relative;
  background: #fff;
  padding: 120px 40px 40px 40px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 0.8rem;
}
.our-testi-slider .our-testi-slider1 .testi-visa-type {
  background-color: #CADFED;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #192451;
  font-size: 1.25rem;
  border-radius: 0px 50px 50px 0px;
  font-size: 1.125rem;
  padding: 15px 25px;
  margin-bottom: 16px;
  position: absolute;
  left: 0;
  top: 40px;
  box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
  font-family: "Fraunces", serif;
  font-weight: 600;
}
.our-testi-slider .our-testi-slider1 .testi-visa-type span {
  margin-bottom: 0;
  line-height: normal;
  text-transform: capitalize;
  color: #192451;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.our-testi-slider .our-testi-slider1 .testi-cuatomer-name h2 {
  font-family: "Fraunces", serif;
  font-weight: 600;
  font-size: 24px;
}
.our-testi-slider .our-testi-slider1 .testi-cuatomer-name span {
  margin-left: 5px;
}
.our-testi-slider .our-testi-slider1 .testi-content p {
  margin-bottom: 0px;
  font-family: "SharpSansMedium";
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.our-testi-slider a {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  padding: 15px 40px;
  color: #fff;
  text-decoration: none;
  background-color: #192451;
  border: 1px solid #192451;
  width: 29%;
  width: unset;
}
.our-testi-slider a:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.our-testi-slider a svg {
  margin-left: 7px;
  transition: ease-in 0.2s;
}
.our-testi-slider a svg path {
  fill: #fff;
}
.our-testi-slider a:hover {
  color: #192451;
  background-color: transparent;
  border: 1px solid #192451;
}
.our-testi-slider a:hover svg {
  transform: translate(4px, 0px);
}
.our-testi-slider a:hover svg path {
  fill: #192451;
}

.about-us-page .welcome-sec, .about-us-page .happyclients-sec {
  padding: 80px 0;
}
.about-us-page .welcome-sec .left-side, .about-us-page .happyclients-sec .left-side {
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  height: 100%;
}
.about-us-page .welcome-sec .left-side .sec-title2 h2, .about-us-page .welcome-sec .left-side .sec-title3 h2, .about-us-page .happyclients-sec .left-side .sec-title2 h2, .about-us-page .happyclients-sec .left-side .sec-title3 h2 {
  color: #192451;
  font-size: 3.375rem;
  font-family: "Fraunces", serif;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  margin-bottom: 0;
  color: #192451;
}
.about-us-page .welcome-sec .left-side .sec-text p, .about-us-page .happyclients-sec .left-side .sec-text p {
  color: #222222;
  font-family: SharpSansMedium;
}
.about-us-page .welcome-sec .right-sec img, .about-us-page .happyclients-sec .right-sec img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-us-page .happyclients-sec .row {
  flex-direction: row-reverse;
}
.about-us-page .happyclients-sec .left-side {
  margin-left: 30px;
}
.about-us-page .happyclients-sec .sec-title2 h2 {
  font-size: 75px !important;
}
.about-us-page .our-journey {
  padding: 80px 0;
  background-color: #fff;
}
.about-us-page .our-journey .right-sec {
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.about-us-page .our-journey .right-sec .sec-title {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.about-us-page .our-journey .right-sec .sec-title span {
  color: #192451;
  font-size: 1.125rem;
  font-family: "SharpSansBold";
  background-color: #CADFED;
  border-radius: 30px;
  padding: 15px 30px;
  text-transform: capitalize;
  letter-spacing: 0.72px;
}
.about-us-page .our-journey .right-sec .sec-title2 h2 {
  color: #192451;
  font-size: 3.375rem;
  font-family: "Fraunces", serif;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  margin-bottom: 0;
  text-align: center;
}
.about-us-page .our-journey .right-sec .sec-text p {
  color: #222222;
  font-family: SharpSansMedium;
  text-align: center;
}

.about-us-page .page-banner, .contact-us-page .page-banner, .teams-policies-page .page-banner, .service-page .page-banner, .permanent-status-page .page-banner, .corporate-services-page .page-banner,
.testimonials-page .page-banner, .client-portal-page .page-banner, .free-assessment-page .page-banner, .book-consultation-page .page-banner {
  background-color: #192451;
  position: relative;
  padding: 80px 0px;
}
.about-us-page .page-banner::before, .contact-us-page .page-banner::before, .teams-policies-page .page-banner::before, .service-page .page-banner::before, .permanent-status-page .page-banner::before, .corporate-services-page .page-banner::before,
.testimonials-page .page-banner::before, .client-portal-page .page-banner::before, .free-assessment-page .page-banner::before, .book-consultation-page .page-banner::before {
  content: "";
  background: url("./images/plan-fly-3.png") no-repeat;
  position: absolute;
  top: 0px;
  left: 110px;
  width: 300px;
  height: 210px;
  flex-shrink: 0;
}
.about-us-page .page-banner .right-sec, .contact-us-page .page-banner .right-sec, .teams-policies-page .page-banner .right-sec, .service-page .page-banner .right-sec, .permanent-status-page .page-banner .right-sec, .corporate-services-page .page-banner .right-sec,
.testimonials-page .page-banner .right-sec, .client-portal-page .page-banner .right-sec, .free-assessment-page .page-banner .right-sec, .book-consultation-page .page-banner .right-sec {
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.about-us-page .page-banner .right-sec .sec-title, .contact-us-page .page-banner .right-sec .sec-title, .teams-policies-page .page-banner .right-sec .sec-title, .service-page .page-banner .right-sec .sec-title, .permanent-status-page .page-banner .right-sec .sec-title, .corporate-services-page .page-banner .right-sec .sec-title,
.testimonials-page .page-banner .right-sec .sec-title, .client-portal-page .page-banner .right-sec .sec-title, .free-assessment-page .page-banner .right-sec .sec-title, .book-consultation-page .page-banner .right-sec .sec-title {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.about-us-page .page-banner .right-sec .sec-title span, .contact-us-page .page-banner .right-sec .sec-title span, .teams-policies-page .page-banner .right-sec .sec-title span, .service-page .page-banner .right-sec .sec-title span, .permanent-status-page .page-banner .right-sec .sec-title span, .corporate-services-page .page-banner .right-sec .sec-title span,
.testimonials-page .page-banner .right-sec .sec-title span, .client-portal-page .page-banner .right-sec .sec-title span, .free-assessment-page .page-banner .right-sec .sec-title span, .book-consultation-page .page-banner .right-sec .sec-title span {
  color: #192451;
  font-size: 1.125rem;
  font-family: "SharpSansBold";
  background-color: #CADFED;
  border-radius: 30px;
  padding: 15px 30px;
  text-transform: capitalize;
  letter-spacing: 0.72px;
}
.about-us-page .page-banner .right-sec .sec-title2 h2, .contact-us-page .page-banner .right-sec .sec-title2 h2, .teams-policies-page .page-banner .right-sec .sec-title2 h2, .service-page .page-banner .right-sec .sec-title2 h2, .permanent-status-page .page-banner .right-sec .sec-title2 h2, .corporate-services-page .page-banner .right-sec .sec-title2 h2,
.testimonials-page .page-banner .right-sec .sec-title2 h2, .client-portal-page .page-banner .right-sec .sec-title2 h2, .free-assessment-page .page-banner .right-sec .sec-title2 h2, .book-consultation-page .page-banner .right-sec .sec-title2 h2 {
  color: #192451;
  font-size: 3.375rem;
  font-family: "Fraunces", serif;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  margin-bottom: 0;
  text-align: center;
  color: #fff;
}

.contact-us-page .contact-form {
  padding: 80px 0;
}
.contact-us-page .contact-form .contact-card {
  background-color: #fff;
  border-radius: 26px;
  box-shadow: 0px 3px 6px 0px rgba(164, 164, 164, 0.25);
}
.contact-us-page .contact-form .form-inner {
  padding: 50px 40px;
}
.contact-us-page .contact-form .form-inner h2 {
  font-family: "Fraunces", serif;
  font-weight: 600;
  font-size: 30px;
  color: #192451;
  margin-bottom: 1rem;
}
.contact-us-page .contact-form .form-inner p {
  font-family: "SharpSansMedium";
  margin-bottom: 1.5rem;
}
.contact-us-page .contact-form .form-inner textarea {
  height: 150px !important;
}
.contact-us-page .contact-form .form-inner .form-control, .contact-us-page .contact-form .form-inner .form-select {
  height: 48px;
  border: 1px solid rgba(164, 164, 164, 0.25);
  margin-bottom: 2rem;
}
.contact-us-page .contact-form .form-inner .form-control:focus, .contact-us-page .contact-form .form-inner .form-select:focus {
  border: 2px solid #192451;
  box-shadow: unset;
}
.contact-us-page .contact-form .form-inner button {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  padding: 15px 40px;
  color: #fff;
  text-decoration: none;
  background-color: #192451;
  border: 1px solid #192451;
  width: 29%;
}
.contact-us-page .contact-form .form-inner button:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.contact-us-page .contact-form .form-inner button svg {
  margin-left: 7px;
  transition: ease-in 0.2s;
}
.contact-us-page .contact-form .form-inner button svg path {
  fill: #fff;
}
.contact-us-page .contact-form .form-inner button:hover {
  color: #192451;
  background-color: transparent;
  border: 1px solid #192451;
}
.contact-us-page .contact-form .form-inner button:hover svg {
  transform: translate(4px, 0px);
}
.contact-us-page .contact-form .form-inner button:hover svg path {
  fill: #192451;
}
.contact-us-page .contact-form iframe {
  border-radius: 0pc 26px 26px 0px;
  height: 100%;
}
.contact-us-page .contact-data {
  padding: 0px 0px 80px 0px;
}
.contact-us-page .contact-data .counters-div {
  display: flex;
  gap: 1rem;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.contact-us-page .contact-data .counters-icon {
  width: 65px;
  height: 65px;
  flex-shrink: 0;
  background: #CADFED;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(164, 164, 164, 0.25);
}
.contact-us-page .contact-data .conunters-nos a, .contact-us-page .contact-data .conunters-nos p {
  color: #192451;
  font-family: "SharpSansBold";
  text-underline-position: under;
  font-size: 1.25rem;
  margin-bottom: 0px;
}

.teams-policies-page .terms-data {
  padding: 80px 0;
}
.teams-policies-page .terms-data .terms-content {
  margin-bottom: 2.5rem;
}
.teams-policies-page .terms-data .terms-content h2 {
  font-family: "Fraunces", serif;
  color: #192451;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1rem;
}
.teams-policies-page .terms-data .terms-content p, .teams-policies-page .terms-data .terms-content li {
  font-family: "SharpSansMedium";
}
.teams-policies-page .terms-data .terms-content p a, .teams-policies-page .terms-data .terms-content li a {
  color: #192451;
  text-underline-position: under;
  font-family: "SharpSansSemiBold";
}
.teams-policies-page .terms-data .terms-content div {
  margin-top: 3rem;
}
.teams-policies-page .terms-data .terms-content div .book-btn {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  padding: 15px 40px;
  color: #fff;
  text-decoration: none;
  background-color: #192451;
  border: 1px solid #192451;
  width: 29%;
  margin-top: 2rem;
}
.teams-policies-page .terms-data .terms-content div .book-btn:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.teams-policies-page .terms-data .terms-content div .book-btn svg {
  margin-left: 7px;
  transition: ease-in 0.2s;
}
.teams-policies-page .terms-data .terms-content div .book-btn svg path {
  fill: #fff;
}
.teams-policies-page .terms-data .terms-content div .book-btn:hover {
  color: #192451;
  background-color: transparent;
  border: 1px solid #192451;
}
.teams-policies-page .terms-data .terms-content div .book-btn:hover svg {
  transform: translate(4px, 0px);
}
.teams-policies-page .terms-data .terms-content div .book-btn:hover svg path {
  fill: #192451;
}

.service-page .all-services .top-text, .permanent-status-page .all-services .top-text, .corporate-services-page .all-services .top-text {
  color: #222222;
  font-family: SharpSansMedium;
  margin: 50px 0px;
}
.service-page .all-services .service-details, .permanent-status-page .all-services .service-details, .corporate-services-page .all-services .service-details {
  padding: 80px 0;
}
.service-page .all-services .service-details .left-side, .permanent-status-page .all-services .service-details .left-side, .corporate-services-page .all-services .service-details .left-side {
  display: flex;
  flex-direction: column;
  gap: 26px;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
}
.service-page .all-services .service-details .left-side .sec-title2 h2, .permanent-status-page .all-services .service-details .left-side .sec-title2 h2, .corporate-services-page .all-services .service-details .left-side .sec-title2 h2 {
  color: #192451;
  font-size: 3.375rem;
  font-family: "Fraunces", serif;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.service-page .all-services .service-details .left-side .sec-text p, .permanent-status-page .all-services .service-details .left-side .sec-text p, .corporate-services-page .all-services .service-details .left-side .sec-text p {
  color: #222222;
  font-family: "SharpSansMedium";
}
.service-page .all-services .service-details .basic-eligibility h3, .permanent-status-page .all-services .service-details .basic-eligibility h3, .corporate-services-page .all-services .service-details .basic-eligibility h3 {
  font-family: "Fraunces", serif;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 1rem;
}
.service-page .all-services .service-details .basic-eligibility .key-point1, .permanent-status-page .all-services .service-details .basic-eligibility .key-point1, .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 8px;
}
.service-page .all-services .service-details .basic-eligibility .key-point1 svg, .permanent-status-page .all-services .service-details .basic-eligibility .key-point1 svg, .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 svg {
  background: #cadfed;
  border-radius: 50%;
}
.service-page .all-services .service-details .basic-eligibility .key-point1 p, .permanent-status-page .all-services .service-details .basic-eligibility .key-point1 p, .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 p {
  margin-bottom: 0rem;
  font-family: "SharpSansMedium";
}
.service-page .all-services .service-details .book-button, .permanent-status-page .all-services .service-details .book-button, .corporate-services-page .all-services .service-details .book-button {
  margin-top: 1rem;
}
.service-page .all-services .service-details .book-button a, .permanent-status-page .all-services .service-details .book-button a, .corporate-services-page .all-services .service-details .book-button a {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  padding: 15px 40px;
  color: #192451;
  text-decoration: none;
  background-color: #CADFED;
  border: 1px solid #CADFED;
}
.service-page .all-services .service-details .book-button a:hover, .permanent-status-page .all-services .service-details .book-button a:hover, .corporate-services-page .all-services .service-details .book-button a:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.service-page .all-services .service-details .book-button a:hover, .permanent-status-page .all-services .service-details .book-button a:hover, .corporate-services-page .all-services .service-details .book-button a:hover {
  background-color: transparent;
  border: 1px solid #192451;
}
.service-page .all-services .service-details .book-button svg, .permanent-status-page .all-services .service-details .book-button svg, .corporate-services-page .all-services .service-details .book-button svg {
  margin-left: 7px;
  transition: ease-in 0.2s;
}
.service-page .all-services .service-details .book-button:hover, .permanent-status-page .all-services .service-details .book-button:hover, .corporate-services-page .all-services .service-details .book-button:hover {
  color: #192451;
}
.service-page .all-services .service-details .book-button:hover svg, .permanent-status-page .all-services .service-details .book-button:hover svg, .corporate-services-page .all-services .service-details .book-button:hover svg {
  transform: translate(4px, 0px);
}
.service-page .all-services .service-details .book-button:hover svg path, .permanent-status-page .all-services .service-details .book-button:hover svg path, .corporate-services-page .all-services .service-details .book-button:hover svg path {
  fill: #192451;
}
.service-page .all-services .service-details ul, .permanent-status-page .all-services .service-details ul, .corporate-services-page .all-services .service-details ul {
  padding-inline-start: 60px;
}
.service-page .all-services .service-details ul li, .permanent-status-page .all-services .service-details ul li, .corporate-services-page .all-services .service-details ul li {
  margin-bottom: 0rem;
  font-family: "SharpSansMedium";
}
.service-page .all-services .service-details img, .permanent-status-page .all-services .service-details img, .corporate-services-page .all-services .service-details img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.testimonials-page .all-testimonials {
  padding: 80px 0;
}

.client-portal-page .client-portal {
  padding: 80px 0;
}
.client-portal-page iframe {
  width: 100%;
  height: 100vh;
}

.free-assessment-page .all-assessment {
  padding: 80px 0;
}
.free-assessment-page .all-assessment .counters-sec {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  z-index: 1;
}
.free-assessment-page .all-assessment .counters-sec .counters-div {
  padding: 35px 55px 50px 55px;
  width: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(1) {
  border-radius: 20px 0px 0px 0px;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
}
.free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(2) {
  border-radius: 0px 20px 0px 0px;
  border-bottom: 1px solid #E8E8E8;
  border-left: 1px solid #E8E8E8;
}
.free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(3) {
  border-radius: 0px 0px 0px 0px;
  border-top: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
}
.free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(4) {
  border-radius: 0px 0px 0px 0px;
  border-top: 1px solid #E8E8E8;
  border-left: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
}
.free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(5) {
  border-radius: 0px 0px 0px 0px;
  border-top: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
}
.free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(6) {
  border-radius: 0px 0px 0px 0px;
  border-top: 1px solid #E8E8E8;
  border-left: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
}
.free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(7) {
  border-radius: 0px 0px 0px 20px;
  border-top: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
}
.free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(8) {
  border-radius: 0px 0px 20px 0px;
  border-top: 1px solid #E8E8E8;
  border-left: 1px solid #E8E8E8;
}
.free-assessment-page .all-assessment .counters-sec .counters-div::before {
  content: "";
  width: 3px;
  height: 76px;
  background: #192451;
  position: absolute;
  left: 0;
}
.free-assessment-page .all-assessment .counters-sec .conunters-nos h2 {
  color: #192451;
  font-family: "Fraunces", serif;
  line-height: 1.2;
  font-size: 29px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-bottom: 1rem;
}
.free-assessment-page .all-assessment .counters-sec .conunters-nos p {
  margin-bottom: 0px;
  font-size: 1rem;
  color: #222222;
  font-family: "SharpSansMedium";
}
.free-assessment-page .all-assessment .book-button {
  margin-top: 1rem;
}
.free-assessment-page .all-assessment .book-button a {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  padding: 15px 40px;
  color: #192451;
  text-decoration: none;
  background-color: #CADFED;
  border: 1px solid #CADFED;
}
.free-assessment-page .all-assessment .book-button a:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.free-assessment-page .all-assessment .book-button a:hover {
  background-color: transparent;
  border: 1px solid #192451;
}
.free-assessment-page .all-assessment .book-button svg {
  margin-left: 7px;
  transition: ease-in 0.2s;
}
.free-assessment-page .all-assessment .book-button:hover {
  color: #192451;
}
.free-assessment-page .all-assessment .book-button:hover svg {
  transform: translate(4px, 0px);
}
.free-assessment-page .all-assessment .book-button:hover svg path {
  fill: #192451;
}

.book-consultation-page .book-consultation iframe {
  width: 100%;
  height: 1100px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.7);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.circle {
  width: 25px;
  height: 25px;
  background: linear-gradient(90deg, hsl(228, 53%, 21%) 0%, hsl(228, 53%, 21%) 12%, hsl(228, 51%, 37%) 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.circle:before {
  content: "";
  width: 25px;
  height: 25px;
  background: linear-gradient(#192451, #192451);
  border-radius: 50%;
  opacity: 0.7;
  animation: loading 1.3s;
  animation-iteration-count: infinite;
}

@keyframes loading {
  100% {
    transform: scale(2, 2);
    opacity: 0;
  }
}
.circle:nth-child(2):before {
  animation-delay: 0.3s;
}

.circle:nth-child(3):before {
  animation-delay: 0.6s;
}

.circle:nth-child(4):before {
  animation-delay: 0.9s;
}

.circle:nth-child(5):before {
  animation-delay: 1.2s;
}

.loading-text {
  width: 100%;
  position: absolute;
  top: 55%;
  left: 0%;
  text-align: center;
}

.modal .modal-content {
  padding: 1rem;
}
.modal .modal-body {
  text-align: center;
}
.modal .modal-body p {
  font-family: "SharpSansMedium";
}
.modal .tick-svg {
  text-align: center;
  margin: 1rem 1rem 2rem 1rem;
}
.modal .modal-footer {
  border-top: unset;
  justify-content: center;
}
.modal .modal-footer button {
  font-family: "SharpSansSemibold";
  border-radius: 6px;
  background-color: #FAFAFA;
  border: 1px solid #FAFAFA;
  transition: ease-in 0.2s;
  padding: 15px 40px;
  color: #fff;
  text-decoration: none;
  background-color: #192451;
  border: 1px solid #192451;
  width: 29%;
  width: unset;
  padding: 10px 30px;
}
.modal .modal-footer button:hover {
  background-color: transparent;
  border: 1px solid #FAFAFA;
}
.modal .modal-footer button svg {
  margin-left: 7px;
  transition: ease-in 0.2s;
}
.modal .modal-footer button svg path {
  fill: #fff;
}
.modal .modal-footer button:hover {
  color: #192451;
  background-color: transparent;
  border: 1px solid #192451;
}
.modal .modal-footer button:hover svg {
  transform: translate(4px, 0px);
}
.modal .modal-footer button:hover svg path {
  fill: #192451;
}

@media only screen and (max-width: 576px) {
  body {
    overflow-x: hidden;
  }
  .navbar-nav .dropdown-item {
    width: 100%;
    padding: 0.8rem 0rem 0.8rem 1rem;
  }
  .navbar-nav .nav-item {
    margin-bottom: 0.6rem;
  }
  .main-banner .banner-flex .sec-width {
    width: 100%;
  }
  .main-banner .right-sec::before {
    content: "";
    width: 200px;
    height: 145px;
    background-size: contain;
    left: 0px;
  }
  .main-banner .left-sec {
    display: none;
  }
  .main-banner .banner-flex {
    flex-wrap: wrap;
  }
  .main-banner .right-sec h1 {
    font-size: 2.8rem;
    font-weight: 600;
  }
  .main-banner .right-sec {
    padding: 140px 0px;
    margin-left: 15px;
  }
  .main-banner .left-sec img {
    height: unset;
  }
  .flex-custome-column-reverse {
    flex-direction: column-reverse;
  }
  .about-us,
  .our-success,
  .our-services,
  .why-choose-us,
  .our-work-process,
  .our-testi-slider,
  .main-footer {
    padding: 30px 0;
  }
  .about-us .left-side,
  .why-choose-us .left-side {
    margin-left: 0px;
    margin-bottom: 1.5rem;
  }
  .about-us .left-side .sec-title2 h2,
  .our-success .right-side .sec-title2 h2,
  .why-choose-us .left-side .sec-title2 h2,
  .our-work-process .right-side .sec-title2 h2,
  .our-testi-slider .right-side .sec-title2 h2 {
    font-size: 2.4rem;
    line-height: 45px;
    text-align: left;
    width: 100%;
  }
  .about-us .left-side a {
    width: 100%;
    text-align: center;
  }
  .about-us .left-side .sec-title span,
  .our-success .right-side .sec-title span,
  .our-services .right-side .sec-title span,
  .why-choose-us .left-side .sec-title span,
  .our-work-process .right-side .sec-title span,
  .our-testi-slider .right-side .sec-title span,
  .about-us-page .our-journey .right-sec .sec-title span {
    font-size: 1rem;
    padding: 12px 26px;
  }
  .our-success .right-side {
    flex-wrap: nowrap;
  }
  .our-success .right-side .sec-text p {
    width: 100%;
  }
  .our-success .counters-sec .counters-div {
    width: 100%;
    padding: 30px 40px;
    gap: 2rem;
  }
  .our-services .our-services-slider .sliderCard .slideNumber {
    width: 55px;
    height: 55px;
  }
  .our-services .our-services-slider .sliderCard .card-details .visa-title,
  .our-services .our-services-slider .sliderCard .card-details .readmore a {
    font-size: 1rem;
  }
  .our-services .our-services-slider .sliderCard .card-details .readmore a {
    padding: 13px 39px;
  }
  .our-success .counters-sec .counters-icon {
    width: 75px;
    height: 75px;
  }
  .our-success .counters-sec .conunters-nos h2,
  .our-services .right-side .sec-title2 h2 {
    font-size: 2.8rem;
    line-height: 1.2;
  }
  .our-success .counters-sec .conunters-nos p {
    font-size: 1rem;
  }
  .our-success .counters-sec .counters-div:nth-child(1) {
    border-radius: 20px 20px 0px 0px;
    border-right: unset;
    border-bottom: 1px solid #E8E8E8;
  }
  .our-success .counters-sec .counters-div:nth-child(2) {
    border-radius: unset;
    border-bottom: 1px solid #E8E8E8;
    border-left: unset;
  }
  .our-success .counters-sec .counters-div:nth-child(3) {
    border-radius: unset;
    border-top: unset;
    border-right: unset;
    border-bottom: 1px solid #E8E8E8;
  }
  .our-success .counters-sec .counters-div:nth-child(4) {
    border-radius: 0px 0px 20px 20px;
    border-top: unset;
    border-left: unset;
  }
  .our-success .counters-sec::after {
    width: 185.327px;
    height: 139.05px;
    bottom: -10%;
    right: 0%;
    background-size: cover;
    transform: rotate(69deg);
  }
  .our-services .right-side .sec-text p,
  .our-work-process .right-side .sec-text p {
    width: 100%;
    text-align: left;
  }
  .our-services .our-services-slider .prevarrow,
  .our-testi-slider .our-testi-slider1 .prevarrow {
    height: 50px;
    width: 50px;
    top: 104%;
    z-index: 99;
    bottom: -72px;
    left: 63%;
  }
  .our-services .our-services-slider .nextarrow,
  .our-testi-slider .our-testi-slider1 .nextarrow {
    height: 50px;
    width: 50px;
    top: 104%;
    z-index: 99;
    bottom: -72px;
    right: 7%;
  }
  .our-testi-slider .our-testi-slider1 .prevarrow {
    height: 50px;
    width: 50px;
    top: 100%;
    z-index: 99;
    bottom: -72px;
    left: 63%;
  }
  .our-testi-slider .our-testi-slider1 .nextarrow {
    height: 50px;
    width: 50px;
    top: 100%;
    z-index: 99;
    bottom: -72px;
    right: 7%;
  }
  .slick-slider {
    margin-bottom: 1rem;
  }
  .our-testi-slider1 .sliderCard {
    margin-bottom: 2rem;
  }
  .why-choose-us .left-side .key-points .key-point1 {
    width: 47%;
  }
  .our-work-process .process-steps-card,
  .our-testi-slider .our-testi-slider1 .testi-card {
    margin-top: 30px;
  }
  .our-work-process .process-steps-card,
  .our-testi-slider .our-testi-slider1 .testi-card {
    padding: 120px 30px 60px 30px;
  }
  .our-testi-slider .our-testi-slider1 .testi-content p {
    -webkit-line-clamp: 9;
  }
  .page-bottom .sec-content::before {
    left: 56%;
    width: 186px;
    height: 116px;
    background-size: contain;
  }
  .page-bottom .sec-content h2 {
    font-size: 2.375rem;
    margin-bottom: 1.5rem;
  }
  .page-bottom .sec-content h3 a {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  .page-bottom .sec-content::after {
    width: 186px;
    height: 92px;
    background-size: contain;
    right: 50%;
  }
  .page-bottom .sec-content p {
    width: 100%;
  }
  .main-footer h4 {
    margin: 1.5rem 0rem;
  }
  .mini-footer .dev-name {
    text-align: left;
    margin-top: 1rem;
  }
  .about-us-page .page-banner, .contact-us-page .page-banner, .teams-policies-page .page-banner, .service-page .page-banner, .permanent-status-page .page-banner, .corporate-services-page .page-banner,
  .testimonials-page .page-banner, .client-portal-page .page-banner, .free-assessment-page .page-banner, .book-consultation-page .page-banner {
    padding: 130px 0px 100px 0px;
  }
  .about-us-page .page-banner::before, .contact-us-page .page-banner::before, .teams-policies-page .page-banner::before, .service-page .page-banner::before, .permanent-status-page .page-banner::before, .corporate-services-page .page-banner::before,
  .testimonials-page .page-banner::before, .client-portal-page .page-banner::before, .free-assessment-page .page-banner::before, .book-consultation-page .page-banner::before {
    content: "";
    left: 0%;
    width: 181px;
    height: 189px;
    background-size: contain;
  }
  .about-us-page .page-banner .right-sec .sec-title span, .contact-us-page .page-banner .right-sec .sec-title span, .teams-policies-page .page-banner .right-sec .sec-title span, .service-page .page-banner .right-sec .sec-title span, .permanent-status-page .page-banner .right-sec .sec-title span, .corporate-services-page .page-banner .right-sec .sec-title span,
  .testimonials-page .page-banner .right-sec .sec-title span, .client-portal-page .page-banner .right-sec .sec-title span, .free-assessment-page .page-banner .right-sec .sec-title span, .book-consultation-page .page-banner .right-sec .sec-title span {
    font-size: 1rem;
    text-align: center;
  }
  .about-us-page .page-banner .right-sec .sec-title2 h2, .contact-us-page .page-banner .right-sec .sec-title2 h2, .teams-policies-page .page-banner .right-sec .sec-title2 h2, .service-page .page-banner .right-sec .sec-title2 h2, .permanent-status-page .page-banner .right-sec .sec-title2 h2, .corporate-services-page .page-banner .right-sec .sec-title2 h2,
  .testimonials-page .page-banner .right-sec .sec-title2 h2, .client-portal-page .page-banner .right-sec .sec-title2 h2, .free-assessment-page .page-banner .right-sec .sec-title2 h2, .book-consultation-page .page-banner .right-sec .sec-title2 h2 {
    font-size: 2rem;
    line-height: 45px;
  }
  .all-testimonials, .free-assessment-page .all-assessment,
  .client-portal-page .client-portal, .about-us-page .welcome-sec, .about-us-page .happyclients-sec,
  .about-us-page .our-journey, .contact-us-page .contact-form {
    padding: 50px 0px;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div {
    width: 100%;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div {
    padding: 33px 33px 50px 33px;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(1) {
    border-radius: 20px 20px 0px 0px;
    border-right: 0px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(2),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(3),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(4),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(5),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(6),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(7) {
    border-radius: 0px 0px 0px 0px;
    border-bottom: 1px solid #E8E8E8;
    border-top: 1px solid #E8E8E8;
    border-left: 0px solid #E8E8E8;
    border-right: 0px solid #E8E8E8;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(8) {
    border-radius: 0px 0px 20px 20px;
    border-left: 0px solid #E8E8E8;
  }
  .about-us-page .happyclients-sec .left-side {
    margin-left: 0px;
  }
  .about-us-page .happyclients-sec .sec-title2 h2 {
    font-size: 60px !important;
  }
  .about-us-page .happyclients-sec .left-side .sec-title3 h2 {
    font-size: 2.8rem;
  }
  .about-us-page .happyclients-sec .left-side {
    gap: 15px;
  }
  .about-us-page .our-journey .right-sec .sec-title2 h2 {
    font-size: 2rem;
    line-height: 40px;
  }
  .contact-us-page .contact-form .form-inner {
    padding: 40px 30px;
  }
  .contact-us-page .contact-form .form-inner button {
    width: 100%;
  }
  .contact-us-page .contact-form iframe {
    border-radius: 0pc 0px 26px 26px;
    height: 100%;
  }
  .contact-us-page .contact-data .counters-div {
    margin-bottom: 1.2rem;
  }
  .contact-us-page .contact-data .conunters-nos a, .contact-us-page .contact-data .conunters-nos p {
    font-size: 1rem;
  }
  .contact-us-page .contact-data .counters-icon {
    width: 55px;
    height: 55px;
  }
  .service-page .all-services .service-details .left-side .sec-title2 h2,
  .permanent-status-page .all-services .service-details .left-side .sec-title2 h2,
  .corporate-services-page .all-services .service-details .left-side .sec-title2 h2 {
    font-size: 2.375rem;
    line-height: 45px;
  }
  .service-page .all-services .service-details .left-side,
  .permanent-status-page .all-services .service-details .left-side,
  .corporate-services-page .all-services .service-details .left-side {
    gap: 20px;
  }
  .service-page .all-services .service-details .basic-eligibility .key-point1 svg,
  .permanent-status-page .all-services .service-details .basic-eligibility .key-point1 svg,
  .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 svg {
    width: 25px;
    height: 25px;
  }
  .service-page .all-services .service-details .book-button,
  .permanent-status-page .all-services .service-details .book-button,
  .corporate-services-page .all-services .service-details .book-button {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .service-page .all-services .service-details,
  .permanent-status-page .all-services .service-details, .corporate-services-page .all-services .service-details {
    padding: 30px 0px;
  }
}
@media only screen and (min-width: 577px) and (max-width: 767px) {
  .navbar-nav .nav-item {
    margin-bottom: 0.6rem;
  }
  .navbar-nav .dropdown-item {
    width: 100%;
    padding: 0.8rem 0rem 0.8rem 1rem;
  }
  .main-banner .banner-flex .sec-width {
    width: 100%;
  }
  .main-banner .right-sec::before {
    content: "";
    width: 213px;
    height: 157px;
    background-size: contain;
    left: 0px;
  }
  .main-banner .right-sec {
    margin-left: 15px;
  }
  .main-banner .left-sec {
    display: none;
  }
  .main-banner .banner-flex {
    flex-wrap: wrap;
    margin-left: 66px;
  }
  .main-banner .right-sec h1 {
    font-size: 2.8rem;
    font-weight: 600;
  }
  .main-banner .right-sec {
    padding: 150px 0px;
  }
  .main-banner .left-sec img {
    height: unset;
  }
  .flex-custome-column-reverse {
    flex-direction: column-reverse;
  }
  .about-us,
  .our-success,
  .our-services,
  .why-choose-us,
  .our-work-process,
  .our-testi-slider,
  .main-footer {
    padding: 50px 0;
  }
  .about-us .left-side,
  .why-choose-us .left-side {
    margin-left: 0px;
    margin-bottom: 1.5rem;
  }
  .about-us .left-side .sec-title2 h2,
  .our-success .right-side .sec-title2 h2,
  .why-choose-us .left-side .sec-title2 h2,
  .our-work-process .right-side .sec-title2 h2,
  .our-testi-slider .right-side .sec-title2 h2 {
    font-size: 2.4rem;
    line-height: 45px;
    text-align: left;
    width: 100%;
  }
  .about-us .left-side a {
    width: 100%;
    text-align: center;
  }
  .about-us .left-side .sec-title span,
  .our-success .right-side .sec-title span,
  .our-services .right-side .sec-title span,
  .why-choose-us .left-side .sec-title span,
  .our-work-process .right-side .sec-title span,
  .our-testi-slider .right-side .sec-title span {
    font-size: 1rem;
    padding: 12px 26px;
  }
  .our-success .right-side {
    flex-wrap: nowrap;
  }
  .our-success .right-side .sec-text p {
    width: 100%;
  }
  .our-success .counters-sec .counters-div {
    width: 100%;
    padding: 30px 40px;
  }
  .our-services .our-services-slider .sliderCard .slideNumber {
    width: 55px;
    height: 55px;
  }
  .our-services .our-services-slider .sliderCard .card-details .visa-title,
  .our-services .our-services-slider .sliderCard .card-details .readmore a {
    font-size: 1rem;
  }
  .our-services .our-services-slider .sliderCard .card-details .readmore a {
    padding: 13px 39px;
  }
  .our-success .counters-sec .counters-icon {
    width: 75px;
    height: 75px;
  }
  .our-success .counters-sec .conunters-nos h2,
  .our-services .right-side .sec-title2 h2 {
    font-size: 2.8rem;
    line-height: 1.2;
  }
  .our-success .counters-sec .conunters-nos p {
    font-size: 1rem;
  }
  .our-success .counters-sec .counters-div:nth-child(1) {
    border-radius: 20px 20px 0px 0px;
    border-right: unset;
    border-bottom: 1px solid #E8E8E8;
  }
  .our-success .counters-sec .counters-div:nth-child(2) {
    border-radius: unset;
    border-bottom: 1px solid #E8E8E8;
    border-left: unset;
  }
  .our-success .counters-sec .counters-div:nth-child(3) {
    border-radius: unset;
    border-top: unset;
    border-right: unset;
    border-bottom: 1px solid #E8E8E8;
  }
  .our-success .counters-sec .counters-div:nth-child(4) {
    border-radius: 0px 0px 20px 20px;
    border-top: unset;
    border-left: unset;
  }
  .our-success .counters-sec::after {
    width: 213.327px;
    height: 162.05px;
    bottom: -18%;
    background-size: cover;
    transform: rotate(169deg);
    right: -8%;
  }
  .our-services .right-side .sec-text p,
  .our-work-process .right-side .sec-text p {
    width: 100%;
    text-align: left;
  }
  .our-services .our-services-slider .prevarrow,
  .our-testi-slider .our-testi-slider1 .prevarrow {
    height: 50px;
    width: 50px;
    top: 104%;
    z-index: 99;
    bottom: -72px;
    left: 71%;
  }
  .our-services .our-services-slider .nextarrow,
  .our-testi-slider .our-testi-slider1 .nextarrow {
    height: 50px;
    width: 50px;
    top: 104%;
    z-index: 99;
    bottom: -72px;
    right: 7%;
  }
  .our-testi-slider .our-testi-slider1 .prevarrow {
    height: 50px;
    width: 50px;
    top: 100%;
    z-index: 99;
    bottom: -72px;
    left: 71%;
  }
  .our-testi-slider .our-testi-slider1 .nextarrow {
    height: 50px;
    width: 50px;
    top: 100%;
    z-index: 99;
    bottom: -72px;
    right: 7%;
  }
  .our-testi-slider .our-testi-slider1 .testi-content p {
    -webkit-line-clamp: 6;
  }
  .slick-slider {
    margin-bottom: 1rem;
  }
  .our-testi-slider1 .sliderCard {
    margin-bottom: 2rem;
  }
  .why-choose-us .left-side .key-points .key-point1 {
    width: 47%;
  }
  .our-work-process .process-steps-card,
  .our-testi-slider .our-testi-slider1 .testi-card {
    margin-top: 30px;
  }
  .our-work-process .process-steps-card,
  .our-testi-slider .our-testi-slider1 .testi-card {
    padding: 120px 30px 60px 30px;
  }
  .page-bottom .sec-content::before {
    left: 72%;
    width: 186px;
    height: 116px;
    background-size: contain;
  }
  .page-bottom .sec-content h2 {
    font-size: 2.375rem;
    margin-bottom: 1.5rem;
  }
  .page-bottom .sec-content h3 a {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  .page-bottom .sec-content::after {
    width: 186px;
    height: 92px;
    background-size: contain;
    right: 69%;
  }
  .page-bottom .sec-content p {
    width: 100%;
  }
  .main-footer h4 {
    margin: 1.5rem 0rem;
  }
  .mini-footer .dev-name {
    text-align: left;
    margin-top: 1rem;
  }
  .about-us-page .page-banner, .contact-us-page .page-banner, .teams-policies-page .page-banner, .service-page .page-banner, .permanent-status-page .page-banner, .corporate-services-page .page-banner,
  .testimonials-page .page-banner, .client-portal-page .page-banner, .free-assessment-page .page-banner, .book-consultation-page .page-banner {
    padding: 130px 0px 100px 0px;
  }
  .about-us-page .page-banner::before, .contact-us-page .page-banner::before, .teams-policies-page .page-banner::before, .service-page .page-banner::before, .permanent-status-page .page-banner::before, .corporate-services-page .page-banner::before,
  .testimonials-page .page-banner::before, .client-portal-page .page-banner::before, .free-assessment-page .page-banner::before, .book-consultation-page .page-banner::before {
    left: 11%;
    width: 181px;
    height: 189px;
    background-size: contain;
  }
  .about-us-page .page-banner .right-sec .sec-title span, .contact-us-page .page-banner .right-sec .sec-title span, .teams-policies-page .page-banner .right-sec .sec-title span, .service-page .page-banner .right-sec .sec-title span, .permanent-status-page .page-banner .right-sec .sec-title span, .corporate-services-page .page-banner .right-sec .sec-title span,
  .testimonials-page .page-banner .right-sec .sec-title span, .client-portal-page .page-banner .right-sec .sec-title span, .free-assessment-page .page-banner .right-sec .sec-title span, .book-consultation-page .page-banner .right-sec .sec-title span {
    font-size: 1rem;
    text-align: center;
  }
  .about-us-page .page-banner .right-sec .sec-title2 h2, .contact-us-page .page-banner .right-sec .sec-title2 h2, .teams-policies-page .page-banner .right-sec .sec-title2 h2, .service-page .page-banner .right-sec .sec-title2 h2, .permanent-status-page .page-banner .right-sec .sec-title2 h2, .corporate-services-page .page-banner .right-sec .sec-title2 h2,
  .testimonials-page .page-banner .right-sec .sec-title2 h2, .client-portal-page .page-banner .right-sec .sec-title2 h2, .free-assessment-page .page-banner .right-sec .sec-title2 h2, .book-consultation-page .page-banner .right-sec .sec-title2 h2 {
    font-size: 2rem;
    line-height: 45px;
  }
  .all-testimonials, .free-assessment-page .all-assessment,
  .client-portal-page .client-portal, .about-us-page .welcome-sec, .about-us-page .happyclients-sec,
  .about-us-page .our-journey, .contact-us-page .contact-form {
    padding: 50px 0px;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div {
    width: 100%;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div {
    padding: 33px 33px 50px 33px;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(1) {
    border-radius: 20px 20px 0px 0px;
    border-right: 0px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(2),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(3),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(4),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(5),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(6),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(7) {
    border-radius: 0px 0px 0px 0px;
    border-bottom: 1px solid #E8E8E8;
    border-top: 1px solid #E8E8E8;
    border-left: 0px solid #E8E8E8;
    border-right: 0px solid #E8E8E8;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(8) {
    border-radius: 0px 0px 20px 20px;
    border-left: 0px solid #E8E8E8;
  }
  .about-us-page .happyclients-sec .left-side {
    margin-left: 0px;
  }
  .about-us-page .happyclients-sec .sec-title2 h2 {
    font-size: 60px !important;
  }
  .about-us-page .happyclients-sec .left-side .sec-title3 h2 {
    font-size: 2.8rem;
  }
  .about-us-page .happyclients-sec .left-side {
    gap: 15px;
  }
  .about-us-page .our-journey .right-sec .sec-title2 h2 {
    font-size: 2rem;
    line-height: 40px;
  }
  .contact-us-page .contact-form .form-inner {
    padding: 40px 30px;
  }
  .contact-us-page .contact-form .form-inner button {
    width: 100%;
  }
  .contact-us-page .contact-form iframe {
    border-radius: 0pc 0px 26px 26px;
    height: 100%;
  }
  .contact-us-page .contact-data .counters-div {
    margin-bottom: 1.2rem;
  }
  .service-page .all-services .service-details .left-side .sec-title2 h2,
  .permanent-status-page .all-services .service-details .left-side .sec-title2 h2,
  .corporate-services-page .all-services .service-details .left-side .sec-title2 h2 {
    font-size: 2.375rem;
    line-height: 45px;
  }
  .service-page .all-services .service-details .left-side,
  .permanent-status-page .all-services .service-details .left-side,
  .corporate-services-page .all-services .service-details .left-side {
    gap: 20px;
  }
  .service-page .all-services .service-details .basic-eligibility .key-point1 svg,
  .permanent-status-page .all-services .service-details .basic-eligibility .key-point1 svg,
  .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 svg {
    width: 25px;
    height: 25px;
  }
  .service-page .all-services .service-details .book-button,
  .permanent-status-page .all-services .service-details .book-button,
  .corporate-services-page .all-services .service-details .book-button {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .service-page .all-services .service-details,
  .permanent-status-page .all-services .service-details, .corporate-services-page .all-services .service-details {
    padding: 30px 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .navbar-nav .nav-item {
    margin-bottom: 0.6rem;
  }
  .navbar-nav .dropdown-item {
    width: 100%;
    padding: 0.8rem 0rem 0.8rem 1rem;
  }
  .main-banner .banner-flex .sec-width {
    width: 100%;
  }
  .main-banner .right-sec {
    margin-left: 15px;
  }
  .main-banner .left-sec {
    display: none;
  }
  .main-banner .banner-flex {
    flex-wrap: wrap;
    margin-left: 66px;
  }
  .main-banner .right-sec h1 {
    font-size: 2.8rem;
    font-weight: 600;
  }
  .main-banner .right-sec {
    padding: 150px 0px;
  }
  .main-banner .left-sec img {
    height: unset;
  }
  .main-footer img {
    width: 52%;
  }
  .about-us,
  .our-success,
  .our-services,
  .why-choose-us,
  .our-work-process,
  .our-testi-slider,
  .main-footer {
    padding: 50px 0;
  }
  .about-us .left-side,
  .why-choose-us .left-side {
    margin-left: 0px;
    margin-bottom: 1.5rem;
  }
  .about-us .left-side .sec-title2 h2,
  .our-success .right-side .sec-title2 h2,
  .why-choose-us .left-side .sec-title2 h2,
  .our-work-process .right-side .sec-title2 h2,
  .our-testi-slider .right-side .sec-title2 h2 {
    font-size: 2.4rem;
    line-height: 45px;
    text-align: left;
    width: 100%;
  }
  .about-us .left-side a {
    width: 100%;
    text-align: center;
  }
  .about-us .left-side .sec-title span,
  .our-success .right-side .sec-title span,
  .our-services .right-side .sec-title span,
  .why-choose-us .left-side .sec-title span,
  .our-work-process .right-side .sec-title span,
  .our-testi-slider .right-side .sec-title span {
    font-size: 1rem;
    padding: 12px 26px;
  }
  .our-success .right-side {
    flex-wrap: nowrap;
  }
  .our-success .right-side .sec-text p {
    width: 100%;
  }
  .our-success .counters-sec .counters-div {
    width: 50%;
    padding: 30px 40px;
    gap: 1.5rem;
  }
  .our-services .our-services-slider .sliderCard .slideNumber {
    width: 55px;
    height: 55px;
  }
  .our-services .our-services-slider .sliderCard .card-details {
    top: 66%;
  }
  .our-services .our-services-slider .sliderCard .card-details .visa-title,
  .our-services .our-services-slider .sliderCard .card-details .readmore a {
    font-size: 1rem;
  }
  .our-services .our-services-slider .sliderCard .card-details .readmore a {
    padding: 13px 39px;
  }
  .our-success .counters-sec .counters-icon {
    width: 75px;
    height: 75px;
  }
  .our-success .counters-sec .conunters-nos h2, .our-services .right-side .sec-title2 h2 {
    font-size: 2.5rem;
    line-height: 1.2;
  }
  .our-success .counters-sec .conunters-nos p {
    font-size: 1rem;
  }
  .our-success .counters-sec .counters-div:nth-child(1) {
    border-radius: 20px 20px 0px 0px;
    border-right: unset;
    border-bottom: 1px solid #E8E8E8;
  }
  .our-success .counters-sec .counters-div:nth-child(2) {
    border-radius: unset;
    border-bottom: 1px solid #E8E8E8;
    border-left: unset;
  }
  .our-success .counters-sec .counters-div:nth-child(3) {
    border-radius: unset;
    border-top: unset;
    border-right: unset;
    border-bottom: 1px solid #E8E8E8;
  }
  .our-success .counters-sec .counters-div:nth-child(4) {
    border-radius: 0px 0px 20px 20px;
    border-top: unset;
    border-left: unset;
  }
  .our-success .counters-sec::after {
    width: 260.327px;
    height: 194.05px;
    bottom: -27%;
    background-size: cover;
    transform: rotate(177deg);
    right: 0;
  }
  .our-services .right-side .sec-text p,
  .our-work-process .right-side .sec-text p {
    width: 100%;
    text-align: left;
  }
  .our-services .our-services-slider .prevarrow,
  .our-testi-slider .our-testi-slider1 .prevarrow {
    height: 50px;
    width: 50px;
    top: 104%;
    z-index: 99;
    bottom: -72px;
    left: 82%;
  }
  .our-services .our-services-slider .nextarrow,
  .our-testi-slider .our-testi-slider1 .nextarrow {
    height: 50px;
    width: 50px;
    top: 104%;
    z-index: 99;
    bottom: -72px;
    right: 1%;
  }
  .our-testi-slider .our-testi-slider1 .prevarrow {
    height: 50px;
    width: 50px;
    top: 100%;
    z-index: 99;
    bottom: -72px;
    left: 81%;
  }
  .our-testi-slider .our-testi-slider1 .nextarrow {
    height: 50px;
    width: 50px;
    top: 100%;
    z-index: 99;
    bottom: -72px;
    right: 1%;
  }
  .our-testi-slider .our-testi-slider1 .testi-content p {
    -webkit-line-clamp: 6;
  }
  .slick-slider {
    margin-bottom: 1rem;
  }
  .our-testi-slider1 .sliderCard {
    margin-bottom: 2rem;
  }
  .why-choose-us .left-side .key-points .key-point1 {
    width: 47%;
  }
  .our-work-process .process-steps-card,
  .our-testi-slider .our-testi-slider1 .testi-card {
    margin-top: 30px;
  }
  .our-work-process .process-steps-card,
  .our-testi-slider .our-testi-slider1 .testi-card {
    padding: 120px 30px 60px 30px;
  }
  .page-bottom .sec-content::before {
    left: 75%;
    width: 186px;
    height: 116px;
    background-size: contain;
  }
  .page-bottom .sec-content h2 {
    font-size: 2.375rem;
    margin-bottom: 1.5rem;
  }
  .page-bottom .sec-content h3 a {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  .page-bottom .sec-content::after {
    width: 186px;
    height: 92px;
    background-size: contain;
    right: 72%;
  }
  .page-bottom .sec-content p {
    width: 100%;
  }
  .main-footer h4 {
    margin: 1.5rem 0rem;
  }
  .mini-footer .dev-name {
    text-align: left;
    margin-top: 1rem;
  }
  .about-us-page .page-banner, .contact-us-page .page-banner, .teams-policies-page .page-banner, .service-page .page-banner, .permanent-status-page .page-banner, .corporate-services-page .page-banner,
  .testimonials-page .page-banner, .client-portal-page .page-banner, .free-assessment-page .page-banner, .book-consultation-page .page-banner {
    padding: 130px 0px 100px 0px;
  }
  .about-us-page .page-banner::before, .contact-us-page .page-banner::before, .teams-policies-page .page-banner::before, .service-page .page-banner::before, .permanent-status-page .page-banner::before, .corporate-services-page .page-banner::before,
  .testimonials-page .page-banner::before, .client-portal-page .page-banner::before, .free-assessment-page .page-banner::before, .book-consultation-page .page-banner::before {
    left: 3%;
    width: 181px;
    height: 189px;
    background-size: contain;
  }
  .about-us-page .page-banner .right-sec .sec-title span, .contact-us-page .page-banner .right-sec .sec-title span, .teams-policies-page .page-banner .right-sec .sec-title span, .service-page .page-banner .right-sec .sec-title span, .permanent-status-page .page-banner .right-sec .sec-title span, .corporate-services-page .page-banner .right-sec .sec-title span,
  .testimonials-page .page-banner .right-sec .sec-title span, .client-portal-page .page-banner .right-sec .sec-title span, .free-assessment-page .page-banner .right-sec .sec-title span, .book-consultation-page .page-banner .right-sec .sec-title span {
    font-size: 1rem;
    text-align: center;
  }
  .about-us-page .page-banner .right-sec .sec-title2 h2, .contact-us-page .page-banner .right-sec .sec-title2 h2, .teams-policies-page .page-banner .right-sec .sec-title2 h2, .service-page .page-banner .right-sec .sec-title2 h2, .permanent-status-page .page-banner .right-sec .sec-title2 h2, .corporate-services-page .page-banner .right-sec .sec-title2 h2,
  .testimonials-page .page-banner .right-sec .sec-title2 h2, .client-portal-page .page-banner .right-sec .sec-title2 h2, .free-assessment-page .page-banner .right-sec .sec-title2 h2, .book-consultation-page .page-banner .right-sec .sec-title2 h2 {
    font-size: 2rem;
    line-height: 45px;
  }
  .all-testimonials, .free-assessment-page .all-assessment,
  .client-portal-page .client-portal, .about-us-page .welcome-sec, .about-us-page .happyclients-sec,
  .about-us-page .our-journey, .contact-us-page .contact-form {
    padding: 50px 0px;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div {
    width: 100%;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div {
    padding: 33px 33px 50px 33px;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(1) {
    border-radius: 20px 20px 0px 0px;
    border-right: 0px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(2),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(3),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(4),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(5),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(6),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(7) {
    border-radius: 0px 0px 0px 0px;
    border-bottom: 1px solid #E8E8E8;
    border-top: 1px solid #E8E8E8;
    border-left: 0px solid #E8E8E8;
    border-right: 0px solid #E8E8E8;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(8) {
    border-radius: 0px 0px 20px 20px;
    border-left: 0px solid #E8E8E8;
  }
  .about-us-page .happyclients-sec .left-side {
    margin-left: 0px;
  }
  .about-us-page .happyclients-sec .sec-title2 h2 {
    font-size: 60px !important;
  }
  .about-us-page .happyclients-sec .left-side .sec-title3 h2 {
    font-size: 2.8rem;
  }
  .about-us-page .happyclients-sec .left-side {
    gap: 15px;
  }
  .about-us-page .our-journey .right-sec .sec-title2 h2 {
    font-size: 2rem;
    line-height: 40px;
  }
  .contact-us-page .contact-form .form-inner {
    padding: 40px 30px;
  }
  .contact-us-page .contact-form .form-inner button {
    width: 100%;
  }
  .contact-us-page .contact-form iframe {
    border-radius: 0pc 0px 26px 26px;
    height: 100%;
  }
  .contact-us-page .contact-data .counters-div {
    margin-bottom: 1.2rem;
  }
  .service-page .all-services .service-details .left-side .sec-title2 h2,
  .permanent-status-page .all-services .service-details .left-side .sec-title2 h2,
  .corporate-services-page .all-services .service-details .left-side .sec-title2 h2 {
    font-size: 2.375rem;
    line-height: 45px;
  }
  .service-page .all-services .service-details .left-side,
  .permanent-status-page .all-services .service-details .left-side,
  .corporate-services-page .all-services .service-details .left-side {
    gap: 20px;
  }
  .service-page .all-services .service-details .basic-eligibility .key-point1 svg,
  .permanent-status-page .all-services .service-details .basic-eligibility .key-point1 svg,
  .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 svg {
    width: 25px;
    height: 25px;
  }
  .service-page .all-services .service-details .book-button,
  .permanent-status-page .all-services .service-details .book-button,
  .corporate-services-page .all-services .service-details .book-button {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .service-page .all-services .service-details,
  .permanent-status-page .all-services .service-details, .corporate-services-page .all-services .service-details {
    padding: 30px 0px;
  }
  .main-footer .contactus-details p {
    margin-bottom: 0.8rem;
    word-break: break-word;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .main-banner .banner-flex .sec-width {
    width: 100%;
  }
  .main-banner .right-sec::before {
    content: "";
    width: 220px;
    height: 200px;
    background-size: contain;
    left: 0px;
  }
  .main-banner .right-sec {
    margin-left: 15px;
  }
  .main-banner .left-sec {
    display: none;
  }
  .main-banner .banner-flex {
    flex-wrap: wrap;
    margin-left: 66px;
  }
  .main-banner .right-sec h1 {
    font-size: 4.8rem;
    font-weight: 600;
  }
  .main-banner .right-sec {
    padding: 150px 0px;
  }
  .main-banner .left-sec img {
    height: unset;
  }
  .main-footer img {
    width: 52%;
  }
  .about-us,
  .our-success,
  .our-services,
  .why-choose-us,
  .our-work-process,
  .our-testi-slider,
  .main-footer {
    padding: 50px 0;
  }
  .about-us .left-side {
    margin: 0px 0px 3rem 0px;
  }
  .about-us .left-side a {
    width: 50%;
  }
  .about-us .left-side .sec-title2 h2,
  .why-choose-us .left-side .sec-title2 h2,
  .our-work-process .right-side .sec-title2 h2,
  .our-testi-slider .right-side .sec-title2 h2 {
    font-size: 2.8rem;
    line-height: 50px;
  }
  .our-testi-slider .our-testi-slider1 .testi-card {
    padding: 120px 40px 60px 40px;
  }
  .why-choose-us .left-side {
    margin-left: 0px;
  }
  .our-success .counters-sec .counters-div {
    width: 50%;
  }
  .our-success .counters-sec::after {
    content: "";
    width: 308.327px;
    height: 216.05px;
    bottom: -148px;
    background-size: cover;
    transform: rotate(188deg);
  }
  .our-services .right-side .sec-title2 h2 {
    text-align: center;
  }
  .our-services .our-services-slider .prevarrow,
  .our-testi-slider .our-testi-slider1 .prevarrow {
    height: 50px;
    width: 50px;
    top: 104%;
    z-index: 99;
    bottom: -72px;
    left: 87%;
  }
  .our-services .our-services-slider .nextarrow,
  .our-testi-slider .our-testi-slider1 .nextarrow {
    height: 50px;
    width: 50px;
    top: 104%;
    z-index: 99;
    bottom: -72px;
    right: 1%;
  }
  .our-services .right-side .sec-text p {
    width: 100%;
  }
  .our-services .our-services-slider .sliderCard .card-details {
    top: 60%;
  }
  .our-services .our-services-slider .sliderCard .card-details .visa-title,
  .our-services .our-services-slider .sliderCard .card-details .readmore a {
    padding: 12px 41px;
  }
  .our-services .our-services-slider .sliderCard .slideNumber {
    height: 50px;
    width: 50px;
  }
  .why-choose-us .left-side .key-points {
    margin-bottom: 3rem;
  }
  .our-testi-slider .our-testi-slider1 .testi-content p {
    -webkit-line-clamp: 8;
  }
  .page-bottom {
    padding: 150px 0px;
  }
  .page-bottom .sec-content::before {
    left: 75%;
    width: 310px;
    height: 180px;
    background-size: contain;
  }
  .page-bottom .sec-content::after {
    width: 310px;
    height: 140px;
    background-size: contain;
    right: 65%;
  }
  .contact-us-page .contact-data .counters-div .conunters-nos {
    word-break: break-word;
  }
  .main-footer h4 {
    margin-top: 1.5rem;
  }
  .about-us-page .page-banner, .contact-us-page .page-banner, .teams-policies-page .page-banner, .service-page .page-banner, .permanent-status-page .page-banner, .corporate-services-page .page-banner,
  .testimonials-page .page-banner, .client-portal-page .page-banner, .free-assessment-page .page-banner, .book-consultation-page .page-banner {
    padding: 130px 0px 100px 0px;
  }
  .about-us-page .page-banner::before, .contact-us-page .page-banner::before, .teams-policies-page .page-banner::before, .service-page .page-banner::before, .permanent-status-page .page-banner::before, .corporate-services-page .page-banner::before,
  .testimonials-page .page-banner::before, .client-portal-page .page-banner::before, .free-assessment-page .page-banner::before, .book-consultation-page .page-banner::before {
    left: 3%;
    width: 210px;
    height: 220px;
    background-size: contain;
  }
  .about-us-page .page-banner .right-sec .sec-title span, .contact-us-page .page-banner .right-sec .sec-title span, .teams-policies-page .page-banner .right-sec .sec-title span, .service-page .page-banner .right-sec .sec-title span, .permanent-status-page .page-banner .right-sec .sec-title span, .corporate-services-page .page-banner .right-sec .sec-title span,
  .testimonials-page .page-banner .right-sec .sec-title span, .client-portal-page .page-banner .right-sec .sec-title span, .free-assessment-page .page-banner .right-sec .sec-title span, .book-consultation-page .page-banner .right-sec .sec-title span {
    font-size: 1rem;
    text-align: center;
  }
  .about-us-page .page-banner .right-sec .sec-title2 h2, .contact-us-page .page-banner .right-sec .sec-title2 h2, .teams-policies-page .page-banner .right-sec .sec-title2 h2, .service-page .page-banner .right-sec .sec-title2 h2, .permanent-status-page .page-banner .right-sec .sec-title2 h2, .corporate-services-page .page-banner .right-sec .sec-title2 h2,
  .testimonials-page .page-banner .right-sec .sec-title2 h2, .client-portal-page .page-banner .right-sec .sec-title2 h2, .free-assessment-page .page-banner .right-sec .sec-title2 h2, .book-consultation-page .page-banner .right-sec .sec-title2 h2 {
    font-size: 2.5rem;
    line-height: 55px;
  }
  .all-testimonials, .free-assessment-page .all-assessment,
  .client-portal-page .client-portal, .about-us-page .welcome-sec, .about-us-page .happyclients-sec,
  .about-us-page .our-journey, .contact-us-page .contact-form {
    padding: 50px 0px;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div {
    width: 100%;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div {
    padding: 33px 33px 50px 33px;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(1) {
    border-radius: 20px 20px 0px 0px;
    border-right: 0px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(2),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(3),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(4),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(5),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(6),
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(7) {
    border-radius: 0px 0px 0px 0px;
    border-bottom: 1px solid #E8E8E8;
    border-top: 1px solid #E8E8E8;
    border-left: 0px solid #E8E8E8;
    border-right: 0px solid #E8E8E8;
  }
  .free-assessment-page .all-assessment .counters-sec .counters-div:nth-child(8) {
    border-radius: 0px 0px 20px 20px;
    border-left: 0px solid #E8E8E8;
  }
  .about-us-page .happyclients-sec .left-side {
    margin-left: 0px;
  }
  .about-us-page .happyclients-sec .sec-title2 h2 {
    font-size: 60px !important;
  }
  .about-us-page .happyclients-sec .left-side .sec-title3 h2 {
    font-size: 2.8rem;
  }
  .about-us-page .happyclients-sec .left-side {
    gap: 15px;
  }
  .about-us-page .our-journey .right-sec .sec-title2 h2 {
    font-size: 2rem;
    line-height: 40px;
  }
  .contact-us-page .contact-form .form-inner {
    padding: 40px 30px;
  }
  .contact-us-page .contact-form .form-inner button {
    width: 100%;
  }
  .contact-us-page .contact-form iframe {
    border-radius: 0pc 0px 26px 26px;
    height: 100%;
  }
  .contact-us-page .contact-data .counters-div {
    margin-bottom: 1.2rem;
  }
  .service-page .all-services .service-details .left-side .sec-title2 h2,
  .permanent-status-page .all-services .service-details .left-side .sec-title2 h2,
  .corporate-services-page .all-services .service-details .left-side .sec-title2 h2 {
    font-size: 2.8rem;
    line-height: 55px;
  }
  .service-page .all-services .service-details .left-side,
  .permanent-status-page .all-services .service-details .left-side,
  .corporate-services-page .all-services .service-details .left-side {
    gap: 20px;
  }
  .service-page .all-services .service-details .basic-eligibility .key-point1 svg,
  .permanent-status-page .all-services .service-details .basic-eligibility .key-point1 svg,
  .corporate-services-page .all-services .service-details .basic-eligibility .key-point1 svg {
    width: 25px;
    height: 25px;
  }
  .service-page .all-services .service-details .book-button,
  .permanent-status-page .all-services .service-details .book-button,
  .corporate-services-page .all-services .service-details .book-button {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .service-page .all-services .service-details,
  .permanent-status-page .all-services .service-details, .corporate-services-page .all-services .service-details {
    padding: 30px 0px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1319px) {
  .main-banner .right-sec {
    margin-left: 65px;
  }
  .main-banner .left-sec img {
    height: unset;
  }
  .about-us .left-side {
    margin-left: 20px;
  }
  .about-us .left-side a {
    width: 34%;
  }
  .our-success .counters-sec .counters-div {
    width: 42%;
  }
  .our-success .counters-sec::after {
    content: "";
    width: 312.327px;
    height: 216.05px;
    right: -4%;
    bottom: 10%;
  }
  .about-us,
  .our-success,
  .our-services,
  .why-choose-us,
  .our-work-process,
  .our-testi-slider,
  .main-footer {
    padding: 50px 0;
  }
  .our-testi-slider .our-testi-slider1 .testi-content p {
    -webkit-line-clamp: 6;
  }
  .page-bottom {
    padding: 150px 0px;
  }
  .our-services .our-services-slider .sliderCard .card-details {
    position: absolute;
    top: 65%;
  }
}
@media only screen and (min-width: 1360px) and (max-width: 1439px) {
  .main-banner .left-sec img {
    height: unset;
  }
  .about-us .left-side {
    margin-left: 20px;
  }
  .about-us .left-side a {
    width: 34%;
  }
  .our-success .counters-sec .counters-div {
    width: 42%;
  }
  .our-success .counters-sec::after {
    content: "";
    width: 312.327px;
    height: 216.05px;
    right: -4%;
    bottom: 10%;
  }
  .about-us,
  .our-success,
  .our-services,
  .why-choose-us,
  .our-work-process,
  .our-testi-slider,
  .main-footer {
    padding: 60px 0;
  }
  .our-testi-slider .our-testi-slider1 .testi-content p {
    -webkit-line-clamp: 6;
  }
  .page-bottom {
    padding: 150px 0px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1535px) {
  .main-banner .right-sec {
    margin-left: 59px;
  }
  .our-services .our-services-slider .prevarrow {
    height: 59px;
    width: 59px;
    left: -55px;
  }
  .our-services .our-services-slider .nextarrow {
    height: 59px;
    width: 59px;
    right: -55px;
  }
  .our-services .our-services-slider .prevarrow {
    height: 59px;
    width: 59px;
    left: -55px;
  }
  .our-services .our-services-slider .nextarrow {
    height: 59px;
    width: 59px;
    right: -55px;
  }
  .our-testi-slider .our-testi-slider1 .prevarrow {
    height: 59px;
    width: 59px;
    left: -55px;
    top: 54%;
  }
  .our-testi-slider .our-testi-slider1 .nextarrow {
    height: 59px;
    width: 59px;
    right: -55px;
    top: 54%;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1720px) {
  .main-banner .right-sec {
    margin-left: 134px;
  }
  .about-us-page .page-banner::before, .contact-us-page .page-banner::before, .teams-policies-page .page-banner::before, .service-page .page-banner::before, .permanent-status-page .page-banner::before, .corporate-services-page .page-banner::before,
  .testimonials-page .page-banner::before, .client-portal-page .page-banner::before, .free-assessment-page .page-banner::before, .book-consultation-page .page-banner::before {
    left: 9%;
    width: 210px;
    height: 220px;
    background-size: contain;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2023px) {
  .main-banner .right-sec {
    margin-left: 300px;
  }
  .about-us-page .page-banner::before, .contact-us-page .page-banner::before, .teams-policies-page .page-banner::before, .service-page .page-banner::before, .permanent-status-page .page-banner::before, .corporate-services-page .page-banner::before,
  .testimonials-page .page-banner::before, .client-portal-page .page-banner::before, .free-assessment-page .page-banner::before, .book-consultation-page .page-banner::before {
    left: 16%;
    width: 210px;
    height: 220px;
    background-size: contain;
  }
}
@media only screen and (min-width: 2024px) and (max-width: 2048px) {
  .main-banner .right-sec {
    margin-left: 350px;
  }
  .about-us-page .page-banner::before, .contact-us-page .page-banner::before, .teams-policies-page .page-banner::before, .service-page .page-banner::before, .permanent-status-page .page-banner::before, .corporate-services-page .page-banner::before,
  .testimonials-page .page-banner::before, .client-portal-page .page-banner::before, .free-assessment-page .page-banner::before, .book-consultation-page .page-banner::before {
    left: 18%;
    width: 210px;
    height: 220px;
    background-size: contain;
  }
}/*# sourceMappingURL=index.css.map */